import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import { AnimeButton } from "../../../../common/AnimeButton";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";

import { BsArrowRight } from "react-icons/bs";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { ShareholderInformationform } from "../../../../common/ShareholderInformationform";
import { Link } from "react-router-dom";

import apiData from './DumpSql.json';

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 40px;
  @media (max-width: 900px) {
    padding-top: 10px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  margin:40px 0;
  overflow:auto;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
        }
      }
    }

    th,
    td {
      padding: 10px 15px !important;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
    }
    @media (max-width: 820px) {
      table {
    border-spacing: 0;
    border: 1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
    tr {
      :last-child {
        td {
          border-bottom: 1px;
        }
      }
    }

    th,
    td {
      padding: 10px 15px !important;
      border: 1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 14px;
    }
    }
  }
`;

const TextLight = styled.p`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  line-height: 30px !important;
  margin: 0 !important;
  margin-bottom:10px !important;
  width: 80% !important;
  @media (max-width: 900px) {
    font-size: 14px !important;
    width: 100% !important;
  }
`;

const TextHeading = styled.p`
  margin: 0 !important ;
  font-weight: bold !important ;
  font-size: 18px !important ;
  font-family: Drlcircular !important ;
  margin: 10px 0 !important;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    width: 100% !important ;
    margin-bottom: 20px !important ;
  }
`;

const LinksATag = styled.a`
margin: 0 !important ;
  font-weight: bold !important ;
  font-size: 18px !important ;
  color: #000000 !important;
  font-family: Drlcircular !important ;
  cursor: pointer !important;
  text-decoration:none !important;
  @media (max-width: 900px) {
    font-size: 14px !important ;
  }
`;

const FileMain = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`;

export const InvestorHandbook = (props) => {

  const [handBookFiles, setHandBookFiles] = useState([]);

  useEffect(() => {
    var tempObj = {};
    axios({
      method: "get",
      url:
        config.serverUrl.base +
        "/jsonapi/node/handbook_files?include=field_file&sort=field_sequence_order_",
    })
      .then((res) => {
        var files = res.data;
    // var files = apiData;
    var fileLinks = {};
    var HandBookFilesData = [];
    for (var fileObj of files.included) {
      fileLinks[fileObj.id] = fileObj.attributes.uri.url;
    }
    for (var item of files.data) {
      if (item.attributes.field_external_link && item.attributes.field_external_link.title === "internal link") {
        tempObj = {
          title: item.attributes.title + config.serverUrl.imageBase + item.attributes.field_external_link.uri,
          fileUrl: item.relationships.field_file.data ? config.serverUrl.imageBase + fileLinks[item.relationships.field_file.data.id] : "",
          externalLink: item.attributes.field_external_link ? config.serverUrl.imageBase + item.attributes.field_external_link.uri : ""
        }
      } else {
        tempObj = {
          title: item.attributes.title,
          fileUrl: item.relationships.field_file.data ? config.serverUrl.imageBase + fileLinks[item.relationships.field_file.data.id] : "",
          externalLink: item.attributes.field_external_link ? item.attributes.field_external_link.uri : ""
        }
      }
      HandBookFilesData.push(tempObj);
    }
    setHandBookFiles([...HandBookFilesData])
    })
    .catch((err) => {
      console.log(err, "err");
    });
  }, [])

  return (<IHSubContainer>
    <TextLight>
      Dr. Reddy’s Laboratories Limited (the Company) has appointed M/s. Bigshare Services Private Limited as its Registrar and Transfer Agent (RTA) for handling all matters relating to its shares (both in physical and demat mode). All matters relating to the shares of the Company such as transmission, dematerialisation, rematerialisation, dividend, change of address, etc., related correspondence and queries may be addressed to:<br />
    </TextLight>
    <br />
    <TextHeading>
      Registrar and Transfer Agent (RTA)<br />
    </TextHeading>
    <TextLight>
      Bigshare Services Private Limited<br />
    </TextLight>
    <TextLight>
      (CIN: U99999MH1994PTC076534)<br />
    </TextLight>
    <TextLight>
      306, Right Wing, 3rd Floor, Amrutha Ville,<br />
    </TextLight>
    <TextLight>
      Opp. Yashoda Hospital, Somajiguda, Rajbhavan Road,<br />
    </TextLight>
    <TextLight>
      Hyderabad 500 082<br />
    </TextLight>
    <TextLight>
      Tel. 91-040-23370295/40144582/40144967<br />
    </TextLight>
    <TextLight>
      Email: bsshyd@bigshareonline.com<br />
    </TextLight>
    <br />
    <TextHeading>Shareholders’ Rights</TextHeading>
    <TextLight>
      1. To receive shares on allotment.<br />
    </TextLight>
    <TextLight>
      2. To receive copies of the Annual Report containing the Balance Sheet, the statement of Profit & Loss and the Auditor’s Report and any other correspondences through email or through physical mode (as may be preferred).<br />
    </TextLight>
    <TextLight>
      3. To participate and vote at general meetings either personally or through a proxy/authorised representative.<br />
    </TextLight>
    <TextLight>
      4. To receive dividends in due time once approved at general meetings.<br />
    </TextLight>
    <TextLight>
      5. To inspect the statutory registers and documents as may be permitted under the law.<br />
    </TextLight>
    <TextLight>
      6. To receive corporate benefits like rights, bonus, etc., once approved.<br />
    </TextLight>
    <TextLight>
      7. Other rights as specified in the statutory enactments.<br />
    </TextLight>
    <br />
    <TextHeading> I. Transfer of Shares</TextHeading>
    <TextLight>
      Pursuant to Regulation 40 of SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015, as amended vide Notification No. SEBI/LAD-NRO/GN/2018/24 dated June 8, 2018 and Press Release No: 49/2018 dated December 3, 2018, members may please note that, with effect from April 1, 2019, transfer of shares (except transmission and transposition of shares) shall be carried out in dematerialised form only. Shareholders are therefore requested to dematerialise their shares held in physical mode with a Depository Participant for effecting any transfer of shares. However, the transfer deed(s) lodged with the Company or RTA on or before March 31, 2019, and returned due to deficiency in the documents may be re-lodged for transfer even after the said deadline.<br />
    </TextLight>
    <TextLight>
      Notwithstanding the above, shareholders may continue to hold shares in physical form even after April 01, 2019.<br />
    </TextLight>
    <br />
    <TextHeading>II. Dematerialisation of Shares</TextHeading>
    <TextLight>
      Dematerialisation is the process by which securities held in physical form are cancelled, and the ownership thereof is entered into and retained in a fungible form in a depository by way of electronic balances.<br />
    </TextLight>
    <TextLight>
      In order to dematerialise the shares, the shareholder has to open a demat account with any Depository Participant (DP) and obtain a DPID & Client ID number. Dematerialisation Request Form (DRF) has to be filled up by the shareholder and signed in the same sequence of names as appearing in the concerned share certificate registered in the Company’s records and submitted to DP along with the original physical share certificate(s).<br />
    </TextLight>
    <TextLight>
      The DP will send an electronic request through the Depository to the Registrar and Transfer Agent (RTA), and simultaneously, the DP will forward the DRF and the share certificate(s) to the RTA with a request letter to confirm the demat request. After verifying the documents received from the DP and the shareholder signature(s), the RTA will confirm the demat request to the Depository.<br />
    </TextLight>
    <TextLight>
      After receiving the confirmation from the Depository, the DP will credit the shares to the shareholder(s) demat account.<br />
    </TextLight>
    <TextLight>
      In case of name mismatch/transposition of names, the shareholder will be required to submit a declaration to the DP in the format available with respective DP. Additional documents as under in support of/for rectifying the mismatch of names may also be sought from the shareholder in line with the SEBI circular no. SEBI/HO/MIRSD/RTAMB/CIR/P/2019/122 dated November 5, 2019, namely:<br />
    </TextLight>
    <TextLight>
      1. Copy of Passport<br />
    </TextLight>
    <TextLight>
      2. Copy of legally recognized marriage certificate<br />
    </TextLight>
    <TextLight>
      3. Copy of gazette notification regarding the change in name<br />
    </TextLight>
    <TextLight>
      4. Copy of Aadhar Card<br />
    </TextLight>
    <TextLight>
      5. Any other document, on case to case basis<br />
    </TextLight>
    <br />
    <TextHeading>III. Rematerialisation of Shares</TextHeading>
    <TextLight>
      Every shareholder has the option of holding their shares in electronic or in physical form. Along with demat, they also have the option of converting their shareholding from electronic to the physical form. Rematerialisation is the process through which shares held in demat form are converted into the physical form in the form of share certificate(s). The procedure is similar to that of Dematerialisation. The shareholder has to submit a Rematerialisation Request Form (RRF) to their Depository Participant (DP). The DP will send an electronic request through the Depository to the Registrar and Transfer Agent (RTA), and simultaneously, the DP will forward the RRF to the RTA with a request letter to confirm the remat request. After receiving a request from the DP, the RTA will issue the physical share certificate(s) to the shareholder. The demat account of the shareholder will accordingly be debited post rematerialisation of the shares. However, please note that with effect from April 1, 2019, transfer of shares will only be in dematerialised form.<br />
    </TextLight>
    <br />
    <TextHeading>IV. Nomination Facility</TextHeading>
    <TextLight>
      Section 72 of the Companies Act, 2013 provides the facility of nomination to shareholders. This facility is mainly useful for individuals holding shares in sole name. In the case of joint holding of shares by individuals, the nomination will be effective only in the event of the death of all joint holders.<br />
    </TextLight>
    <TextLight>
      In the case of shareholders holding shares in single name and physical form, the shareholder can avail the nomination facility by submitting the prescribed Form SH-13, duly filled in and signed, along with a self-attested copy of any of the following document in support of proof of identification of the proposed nominee(s): Aadhar Card / PAN Card / Passport / Voter Identity Card to our Registrar and Transfer Agent (RTA). If the nomination form is in order, the RTA will register the nomination and send a confirmation to the shareholder. In case of joint holding, joint holders shall together appoint the nominee and submit the prescribed form and document. Shareholders may cancel/vary nomination made by them by submitting Form SH-14, duly filled in and signed, to the RTA.<br />
    </TextLight>
    <TextLight>
      The prescribed forms SH-13 and SH-14 are available below for download on this webpage.<br />
    </TextLight>
    <TextLight>
      In the case of shares held in demat mode, the shareholder has to approach their Depository Participant (DP) for making a nomination in respect of the shares held by them.<br />
    </TextLight>
    <br />
    <TextHeading>V. Transmission of Shares</TextHeading>
    <TextLight>
      In case of the shareholders holding shares in single name and physical form and in the unfortunate event of the death of such single holder, the legal heir(s)/nominee entitled to transmission of shares would be required to inter alia furnish the following documents in order to claim such shares and become the shareholder of the Company.<br />
    </TextLight>
    <br />
    <TextLight>
      1. Original/Certified true copy of Succession Certificate/Letter of Administration/ Probate of Will (in case of a nomination, these are not required);<br />
    </TextLight>
    <TextLight>
      2. Original/Certified true copy of the death certificate of the deceased shareholder;<br />
    </TextLight>
    <TextLight>
      3. Claimant(s) signature(s) duly attested by their nationalized bank manager on bank’s letterhead mentioning the bank manager’s name, address, employee code and telephone number;<br />
    </TextLight>
    <TextLight>
      4. Original share certificate(s);<br />
    </TextLight>
    <TextLight>
      5. Form for transmission of shares duly filled and signed;<br />
    </TextLight>
    <TextLight>
      6. Self-attested copy of Permanent Account Number (PAN) card of the claimant(s);<br />
    </TextLight>
    <TextLight>
      7. Copy of claimant(s) proof of residence: electricity bill/telephone bill (not more than three months old) /Voter ID/Aadhar card/Passport duly verified by the bank; <br />
    </TextLight>
    <TextLight>
      8. Request letter duly signed by the claimant(s).<br />
    </TextLight>
    <TextLight>
      In case of joint holding(s), in the event of the death of one shareholder, the surviving shareholder(s) are required to submit the following documents.<br />
    </TextLight>
    <TextLight>
      1. Request letter duly signed;<br />
    </TextLight>
    <TextLight>
      2. Self-attested copy of PAN card of the claimant(s);<br />
    </TextLight>
    <TextLight>
      3. Original/Certified true copy of the death certificate of the deceased shareholder;<br />
    </TextLight>
    <TextLight>
      4. Copy of surviving shareholder’s proof of residence: electricity bill/telephone bill (not more than three months old) /Voter ID/Aadhar card/Passport duly verified by the bank;<br />
    </TextLight>
    <TextLight>
      5. Original Share Certificate(s); <br />
    </TextLight>
    <TextLight>
      6. Form for transmission of shares duly filled and signed.<br />
    </TextLight>
    <TextLight>
      On receipt of the above documents and on being satisfied with the identity of the legal heir(s)/nominee/surviving shareholder, the Company would take the necessary action as appropriate. However, the Company may seek additional documents/information from the claimants if so required.<br />
    </TextLight>
    <br />
    <TextHeading>VI. Change of Name and Address</TextHeading>
    <TextHeading>A) Change in Name</TextHeading>
    <TextLight>
      If the shares are in physical form, the shareholder shall send a request letter duly signed as per the specimen signatures registered with the Company/ Registrar and Transfer Agent together with the following self-attested documents:<br />
    </TextLight>
    <TextLight>
      1. Gazette Notification for the name change, marriage certificate, other identity proof etc. for change or variations in name in various documents, share certificates etc.<br />
    </TextLight>
    <TextLight>
      2. Affidavit for change or variations in name in various documents (PAN/Aadhar etc.), share certificates.<br />
    </TextLight>
    <TextLight>
      If the shares are in demat mode, the shareholder can approach their Depository Participant (DP) to effect the change of name.<br />
    </TextLight>
    <br />
    <TextHeading>B) Change of Address</TextHeading>
    <TextLight>
      If the shares are in physical form, the shareholder shall send a request letter duly signed by all the registered holder(s) as per the specimen signatures registered with the Company/ Registrar and Transfer Agent together with the following self-attested documents as proof of residence (of both present and previous addresses) and photo identity:<br />
    </TextLight>
    <TextLight>
      1. Copy of electricity bill/telephone bill (not more than three months old)/voter ID card/driving license/passport/Aadhar card of the sole/first named shareholder; and<br />
    </TextLight>
    <TextLight>
      2. Self-attested copy of PAN Card of all the holders of sharesIf the shares are in demat mode, the shareholder can approach their Depository Participant (DP) to effect the change of address.<br />
    </TextLight>
    <br />
    <TextHeading>VII. Change in status from Resident to Non-Resident</TextHeading>
    <TextLight>
      If the shares are held in physical mode, the shareholders has to inform the Company/ Registrar and Transfer Agent (RTA) by sending a request letter duly signed as per the specimen signature registered with the Company/RTA together with the following documents for change in his/her status from Resident to Non-Resident.<br />
    </TextLight>
    <TextLight>
      1. Self-attested copy of registered address proof as per Company/RTA records and new/foreign address proof and passport copy duly self-attested.<br />
    </TextLight>
    <TextLight>
      2. A declaration that they have complied and will continue to comply with requirements under FEMA.<br />
    </TextLight>
    <TextLight>
      3. Permission from the Reserve Bank of India, if applicable<br />
    </TextLight>
    <TextLight>
      If the shares are in demat mode, the shareholder can approach their Depository Participant (DP) to effect the change in status.<br />
    </TextLight>
    <br />
    <TextHeading>VIII. Change in status from Non-Resident to Resident</TextHeading>
    <TextLight>
      If the shares are held in physical mode, the shareholder has to inform the change in status to the Company/Registrar and Transfer Agent (RTA) by sending a request letter duly signed as per the specimen signature registered with the Company/RTA along with relevant documentation and a confirmation from the bank effecting such change.<br />
    </TextLight>
    <TextLight>
      If the shares are in demat mode, the shareholder can approach their Depository Participant (DP) to effect the change in status.<br />
    </TextLight>
    <br />
    <TextHeading>IX. Dividend and Matured Debentures</TextHeading>
    <TextHeading>A) National Electronic Clearing Service (NECS) Facility for remittance of Dividend electronically</TextHeading>
    <TextLight>
      Under this facility, shareholders can receive dividend electronically by way of direct credit to their bank account. With this service, problems such as loss of dividend warrants during postal transit/fraudulent encashment are avoided. This also expedites credit of dividend directly to the shareholder’s account as compared to the payment through physical dividend warrant.<br />
    </TextLight>
    <TextLight>
      To avail the NECS facility, shareholders holding shares in the physical form need to send their NECS Mandate Form, duly filled in, to the Company’s RTA. However, if shares are held in dematerialized form, NECS mandate has to be sent to the concerned DP directly, in the format prescribed by the DP.<br />
    </TextLight>
    <TextLight>
      In case of change in bank account details where the shares are held in demat mode, the shareholder can approach their DP to effect such change. Where the shares are held in physical form, the shareholder shall send a request letter duly signed by all the registered holder(s) as per the specimen signatures, as registered with the Company, stating the new bank account details along with original cancelled cheque leaf bearing the name of the shareholder.<br />
    </TextLight>
    <TextHeading>B) Unpaid/Unclaimed Dividend and Interest on Debentures and Redemption amount on Debentures.</TextHeading>
    <TextLight>
      Shareholders/Debenture holders, who have not claimed their unpaid/unclaimed dividends /interest/redemption amount and which have not yet been transferred to IEPF, can write to the company’s RTA for making a valid claim for the unclaimed dividends/interest/redemption amount.<br />
    </TextLight>
    <TextLight>
      A request letter duly signed by the holder as per the specimen signature registered with the Company/RTA can be submitted to our Company/RTA with details of folio number (in case the shares are in physical mode) or DPID & Client ID number (in case the shares are in demat mode) and number of shares. The shareholder shall also furnish the particulars of the year for which the dividend/interest/redemption amount was not received. The Company shall verify through its records to verify the claim. If found true, but if the validity period of the dividend warrant has expired i.e. three months, the amount would be paid through NEFT/RTGS, and the shareholder is required to return the original warrant. In case, the shareholder does not have the original dividend warrant, then the amount would be paid through NEFT/RTGS after completion of certain additional formalities, in this regard.<br />
    </TextLight>
    <TextLight>
      Under both the circumstances, the shareholder would have to provide his bank account details along with original cancelled cheque leaf bearing the name of the shareholder.<br />
    </TextLight>
    <TextLight>
      As per section 125 of the Companies Act, 2013, the dividend/interest/redemption amount, which are unclaimed for seven years, will be transferred to the Investor Education and Protection Fund (IEPF).<br />
    </TextLight>
    <TextLight>
      Shareholders/debenture holders are requested to claim the dividend(s)/interest/redemption amount before they are statutorily transferred to the IEPF.<br />
    </TextLight>
    <TextLight>
      The information on unclaimed dividend/interest/redemption amount is available on the company’s website www.drreddys.com<br />
    </TextLight>
    <TextHeading>C) Process for claiming shares and unpaid/unclaimed dividends/interest/redemption transferred to IEPF</TextHeading>
    <TextLight>
      As per section 124(6) of the Companies Act, 2013 and Investor Education and Protection Fund Authority (Accounting, Audit, Transfer and Refund) Rules, 2016, as amended, all shares in respect of which dividend has not been paid or claimed for seven consecutive years or more shall be transferred by the company in the name of IEPF.<br />
    </TextLight>
    <TextLight>
      Details of equity shares and unclaimed dividends/interest/redemption amount liable to be transferred to IEPF are available on the company’s website: www.drreddys.com<br />
    </TextLight>
    <TextLight>
      Any person, whose shares and unpaid/unclaimed dividends/interest/redemption amount are transferred to the IEPF may claim the shares and unpaid/unclaimed dividends/interest/ redemption amount directly from the IEPF by submitting Form IEPF-5 online. The web Form IEPF-5, along with an instruction kit is available on the website of IEPF Authority - http://www.iepf.gov.in/IEPF/corporates.html.<br />
    </TextLight>
    <TextLight>
      Steps to be followed AFTER online submission of Form IEPF 5:<br />
    </TextLight>
    <TextLight>
      After online submission of Form IEPF-5, the claimant is required to send the following physical documents immediately to the Nodal Officer of the Company at Dr. Reddy’s Laboratories Ltd., 8-2-337, Road No. 3, Banjara Hills Hyderabad, Telangana, India – 500 034 for verification of the claim:<br />
    </TextLight>
    <TableContainer>
      <table>
        <tr>
          <th >S.No:</th>
          <th >Documents</th>
          <th >Remarks</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Self-attested Copy of Form IEPF -5</td>
          <td>In case of joint holders, all joint holders are required to sign Form IEPF-5</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Acknowledgement Receipt of Form IEPF-5 (MCA Challan)</td>
          <td>As generated against the SRN on MCA portal after filing form IEPF-5</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Indemnity Bond</td>
          <td>
            Indemnity Bond (original) dated and signed by claimant and witnesses:<br />
            <ul>
              <li>In case the market value of shares and the dividend amount is more than Rs. 10,000/- Indemnity Bond is required on a non-judicial stamp paper with the amount of stamp duty to be paid as per state laws.</li>
              <li>In case the amount of claim is Rs. 10,000/- or less, Indemnity bond can be executed on a plain paper.</li>
              <li>Dividend/interest/redemption amount has to be correctly and clearly mentioned.</li>
              <li>In case of joint holders, all joint holders are required to sign the Indemnity Bond.</li>
              <li>Please refer IEPF.gov.in for the latest format of Indemnity Bond.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Advance Receipt</td>
          <td>
            <ul>
              <li> Advance Receipt (in original) with complete particulars filled in (i.e. details of dividend amount and no. of shares) duly signed & dated by claimant & 2 witnesses along with their addresses.</li>
              <li> No of shares and Dividend /interest/redemption amount has to be correctly and clearly mentioned.</li>
              <li> In case of joint holders, all joint holders are required to sign the Advance receipt.</li>
              <li> Please refer IEPF.gov.in for the latest format of Advance Receipt.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>Proof of Entitlement</td>
          <td>
            <ul>
              <li>Dividend warrant or self-attested copy of share certificate of claiming unpaid/unclaimed dividend</li>
              <li>Original Share Certificate for shares and unclaimed/unpaid dividend transferred to IEPF.</li>
              <li>In case of transmission - legal heir/ successor/nominee shall ensure that the transmission process is completed before filing any claim.</li>
              <li>In case of loss of share certificate – please comply with the process of issue of duplicate share certificate before filing any claim.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Self-attested copy of Aadhar Card</td>
          <td>In case of joint holding, self-attested copy of Aadhar Card of all the joint holders</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Self-attested copy of Pan Card</td>
          <td>In case of joint holding, self-attested copy of Pan Card of all the joint holders</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Self-attested copy of Client Master List</td>
          <td>In case of joint holding, self-attested copy of Client Master List by all the joint holders or NOC from other holders, in case the individual demat account is being submitted.</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Original cancelled cheque leaf</td>
          <td>If cheque leaf doesn’t have Name, Bank and Branch Name, IFSC Code etc. printed on it, copy of passbook, duly attested by the Bank can be submitted.</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Other Attachments</td>
          <td>
            <ul>
              <li>In case of change in name and address from corresponding documents – Affidavit for change in name and address with necessary documents as stated in the section for change in name and address of the shareholder.</li>
              <li>Copy of Passport, OCI and PIO card - For Foreigners and NRI</li>
            </ul>
          </td>
        </tr>
      </table>
    </TableContainer>
    <TextHeading> X. Loss/Misplacement of Share Certificate(s)</TextHeading>
    <TextLight>
      The shareholder has to inform our Registrar and Transfer Agent immediately on the loss/misplacement of share certificate(s). The information should contain the folio number, the details of share certificates lost, number of shares and other particulars. The said information has to be signed by the shareholders in the same sequence, and as per the specimen signature recorded with the Company. RTA, after checking the details and verifying the signature, will mark a cautionary stop on the folio.<br />
    </TextLight>
    <TextLight>
      The following procedure has to be followed for obtaining duplicate share certificate(s).<br />
    </TextLight>
    <TextLight>
      1. Execute an Indemnity (by all shareholder(s)) on ₹300/- non-judicial stamp papers and get it attested by the Bank Manager of a nationalized bank / Notary Public;<br />
    </TextLight>
    <TextLight>
      2. Execute an Affidavit (by all shareholder(s)) on ₹300/- non-judicial stamp papers and get it attested by a Bank Manager / Notary Public;<br />
    </TextLight>
    <TextLight>
      3. Notarised copy of FIR / Police Complaint containing information of shareholder, holding details, folio number and distinctive numbers of share certificate;<br />
    </TextLight>
    <TextLight>
      4. The Company will publish a notice in the newspaper towards the loss/misplacement of share certificate(s). The RTA will inform the shareholder regarding charges of such advertisement, as these will have to be borne by the shareholder;<br />
    </TextLight>
    <TextLight>
      5. Self-attested copies of PAN card(s) and address proof of the shareholder(s) duly attested by the bank Manager along with bank seal, employee code etc.;<br />
    </TextLight>
    <TextLight>
      6. Surety affidavit of the value equal to market value that of shares as on date of execution along with self-attested copies of PAN card and address proof of the Surety/Guarantor duly attested by the bank manager along with bank seal, employee code & branch code; (If the shares are transferred to IEPF – the ID proof of surety has to be also attested by the Notary);<br />
    </TextLight>
    <TextLight>
      7. Request letter duly signed by all shareholder(s) as per the specimen signature registered with the Company/Registrar and Transfer Agent for the issue of duplicate share certificate(s).<br />
    </TextLight>
    <br />
    <TextHeading>XI. Sub-division of Shares</TextHeading>
    <TextLight>
      In the year 2001, the Company had sub-divided the shares of ₹10/- face value into the shares of ₹5/- face value.<br />
    </TextLight>
    <TextLight>
      Kindly note that the share certificate(s) of ₹10/- face value of Dr. Reddy’s Laboratories Ltd. is/are no longer valid.<br />
    </TextLight>
    <TextLight>
      The shareholder(s) who have not surrendered their shares certificate of ₹10/- face value, can surrender the share certificates to Registrar and Transfer Agent to claim the corresponding share certificates of ₹5/- face value of Dr. Reddy’s Laboratories Ltd.<br />
    </TextLight>
    <TextLight>
      In case the share certificate(s) of ₹10/- face value is/are not traceable, the shareholder can write to our Registrar and Transfer Agent for the issue of duplicate share certificate(s) of ₹ 5/- face value after complying with due formalities.<br />
    </TextLight>
    <br />
    <TextHeading>XII. Share Certificates of Amalgamated Companies</TextHeading>
    <TextLight>
      The following companies were amalgamated with Dr. Reddy’s Laboratories Ltd. on various dates:<br />
    </TextLight>
    <TextLight>
      1. Standard Equity Fund Ltd.<br />
    </TextLight>
    <TextLight>
      2. Cheminor Drugs Ltd.<br />
    </TextLight>
    <TextLight>
      3. American Remedies Ltd<br />
    </TextLight>
    <TextLight>
      Kindly note that the share certificate(s) of Standard Equity Fund Ltd., Cheminor Drugs Ltd. and American Remedies Ltd. are no longer valid.<br />
    </TextLight>
    <TextLight>
      The shareholders who have not yet surrendered their share certificates of the above three Companies, can surrender the share certificates to our Registrar and Transfer Agent to claim the corresponding share certificates of ₹5/- face value of Dr. Reddy’s Laboratories Ltd.<br />
    </TextLight>
    <TextLight>
      In case the share certificate(s) of Standard Equity Fund Ltd., Cheminor Drugs Ltd. and American Remedies Ltd. is/are not traceable, the shareholder can write to our Registrar and Transfer Agent for the issue of corresponding duplicate share certificate(s) after complying with due formalities.<br />
    </TextLight>
    <br />
    <TextHeading>XIII. Power of Attorney</TextHeading>
    <TextLight>
      The Power of Attorney holder can submit a certified copy of the Power of Attorney (POA) given by the shareholder to our Registrar and Transfer Agent. The RTA will register the power of attorney and inform the registration number to the power of attorney holder. The power of attorney holder has to mention the registration number in all future correspondence with the RTA.<br />
    </TextLight>
    <br />
    <TextHeading>XIV. Registration of Email ID</TextHeading>
    <TextLight>
      The shareholders can register/update their email address with the Company on <LinksATag target="_blank" href={"http://www.drreddys.com/investors/investor-services/shareholder-information/.aspx"} >http://www.drreddys.com/investors/investor-services/shareholder-information/.aspx</LinksATag><br />
    </TextLight>
    <TextLight>
      For any other queries, the shareholders can mail it to <LinksATag href="mailto:shares@drreddys.com" >shares@drreddys.com</LinksATag> / <LinksATag href={"mailto:bsshyd@bigshareonline.com"} >bsshyd@bigshareonline.com</LinksATag><br />
    </TextLight>
    <br />
    <TextHeading>XV. Registration of PAN/Bank/KYC/Details of Nomination details</TextHeading>
    <TextLight>
      Securities and Exchange Board of India (SEBI) vide Circular dated November 3, 2021, has made it mandatory for the holders of physical securities to furnish PAN, KYC details and details of nomination on or before April 1, 2023. Folios wherein any of the above said document(s)/details are not furnished on or before the said date, shall be frozen by the Registrar and Share Transfer Agent(“RTA”). A summary of the respective SEBI circular(s) is uploaded on <LinksATag href={config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + 'files/static/summary-doc-for-website-upload.pdf'} >here</LinksATag>.<br />
    </TextLight>
    <TextLight>
      The shareholder has to submit the prescribed PAN/Bank Account/KYC details/Details of nomination and related registration form(s) either to <LinksATag href="mailto:shares@drreddys.com" >shares@drreddys.com</LinksATag> / <LinksATag href={"mailto:bsshyd@bigshareonline.com"} >bsshyd@bigshareonline.com</LinksATag><br />
    </TextLight>

    {
      handBookFiles.map((item, index) => {
        return <>
          <FileMain>
            <TextHeading>{item.title}</TextHeading>
            <a target="_blank" href={item.externalLink ? item.externalLink : item.fileUrl} style={{ marginRight: '30px',wordBreak: 'break-all', }}
              className="af-class-link-arrow">
              {/* <div className="af-class-link-circle af-class-purple" /> */}
              <img src="images/downloadPrimary.svg" loading="lazy" alt />
              {/* <img src="images/downloadFFF.svg" loading="lazy" alt className="af-class-white-arrow" /> */}
            </a>
          </FileMain>
          <HR />
        </>
      })
    }

    <TextHeading>XVI. Taxation on Dividend Income</TextHeading>
    <TextLight>
      The Company shall be responsible for payment of Dividend Distribution Tax as per the provisions of Income Tax Act, 1961 or such other amendments from time to time, if applicable.<br />
    </TextLight>
    <TextLight>
      However, the Income Tax liability, if such is applicable, on the dividend earned by the shareholder under the Income Tax Act, 1961 or such other amendment from time to time shall be borne by the respective shareholders and if so required under the prevalent Income Tax laws. The payment shall also be subject to deduction of tax at source.<br />
    </TextLight>

    <FileMain>
      <TextHeading>Tax on dividend - Declarations</TextHeading>
      <a href={config.serverUrl.base + "/sites/default/files/Tax_Declarations.zip" } style={{ marginRight: '30px', wordBreak: 'break-all', }}
        className="af-class-link-arrow">
        <img src="images/downloadPrimary.svg" loading="lazy" alt />
      </a>
    </FileMain>
    <HR />

    <br />
    <TextHeading>Shareholder Contact information:</TextHeading>
    <TextLight>
      Corporate Secretarial<br />
    </TextLight>
    <TextLight>
      Dr. Reddy’s Laboratories Ltd.<br />
    </TextLight>
    <TextLight>
      8-2-337, Road No.3 Banjara Hills<br />
    </TextLight>
    <TextLight>
      Hyderabad – 500034<br />
    </TextLight>
    <TextLight>
      Tel. 91-40-49002900<br />
    </TextLight>
    <TextLight>
      Fax: 91-40-49002999<br />
    </TextLight>
    <TextLight>
      Email: shares@drreddys.com<br />
    </TextLight>
    <br />
    <TextHeading>Shareholder Grievance Redressal Mechanism and Nodal Officer (IEPF)</TextHeading>
    <TextLight>
      For any queries/grievances/complaints/escalations relating to shares held in the Company, please contact:<br />
    </TextLight>
    <TextLight>
      Mr K Randhir Singh<br />
    </TextLight>
    <TextLight>
      Company Secretary & Compliance Officer<br />
    </TextLight>
    <TextLight>
      Dr. Reddy’s Laboratories Ltd.<br />
    </TextLight>
    <TextLight>
      8-2-337, Road No.3 Banjara Hills<br />
    </TextLight>
    <TextLight>
      Hyderabad - 500034<br />
    </TextLight>
    <TextLight>
      Tel. 91-40-49002075<br />
    </TextLight>
    <TextLight>
      Email: shares@drreddys.com<br />
    </TextLight>
    <br />
    <TextHeading>Deputy Nodal Officer (IEPF):</TextHeading>
    <TextLight>
    Mr Kundan Kumar Jha<br />
    </TextLight>
    <TextLight>
    Assistant Company Secretary<br />
    </TextLight>
    <TextLight>
      Dr. Reddy’s Laboratories Ltd.<br />
    </TextLight>
    <TextLight>
      8-2-337, Road No.3 Banjara Hills<br />
    </TextLight>
    <TextLight>
      Hyderabad – 500034<br />
    </TextLight>
    <TextLight>
      Tel. 91-40-49002999<br />
    </TextLight>
    <TextLight>
      Email: shares@drreddys.com<br />
    </TextLight>
  </IHSubContainer>
  )

}
