import React, { useEffect, useState } from 'react';
import config from '../config';

const PiReportNav = () => {

  const [pdfFileUrl, setPdfFileUrl] = useState("");

  useEffect(() => {
    if (window.location.href.indexOf("/pi/") !== -1) {
      // window.location.href = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pi/" + window.location.href.split("/pi/")[1];
      setPdfFileUrl(config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pi/" + window.location.href.split("/pi/")[1]);
      //   window.location.href = window.location.href.split("/pi/").join(config.serverUrl.piFilesPrefix+"pi/");
    }
    if (window.location.href.indexOf("/pil/") !== -1) {
      // window.location.href = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pil/" + window.location.href.split("/pil/")[1];
      setPdfFileUrl(config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pil/" + window.location.href.split("/pil/")[1]);
      //   window.location.href = window.location.href.split("/pil/").join(config.serverUrl.piFilesPrefix+"pil/");
    }
    if (window.location.href.indexOf("/medguide/") !== -1) {
      // window.location.href = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "medguide/" + window.location.href.split("/medguide/")[1];
      setPdfFileUrl(config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "medguide/" + window.location.href.split("/medguide/")[1]);
      //   window.location.href = window.location.href.split("/medguide/").join(config.serverUrl.piFilesPrefix+"medguide/");
    }
  }, [])

  return <div style={{ width: '100vw', height: '100vh', overflow: 'scroll' }} >
    {pdfFileUrl && pdfFileUrl.length !== 0 && <iframe src={`${pdfFileUrl}#view=fitH`} title="pdf" height="100%" width="100%" />}
  </div>
}

export default PiReportNav;