import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import axios from 'axios';
import config from '../config';

import { getNewsResultsUrl, makeSearchResultsForCategories } from '../Utils/Utils';

import BackIcon from './images/backArrowFFF.svg';
import BackIconPrimary from './images/backArrowPrimary.svg';
import CloseIconFFF from './images/Icon-feather-menu-close-fff.svg';
import PrimaryBackFullIcon from '../assets/PrimaryBackFullIcon.svg';

import SearchResults from './searchResults.json';
import { scroller } from 'react-scroll';

import { isTab, mediaQueryRangeTab } from '../Utils/Utils';

import { CAlert } from './CAlert';

const MenuSection = (props) => {

  const history = useHistory();

  const [selectedViewMargin, setSelectedViewMargin] = React.useState(0);
  const [gridFrameNum, setGridFrameNum] = React.useState(0);
  const divParentRef = useRef(null);
  const [countrySiteBool, setCountrySiteBool] = useState(false);
  const [selectedView, setSelectedView] = React.useState({});

  const [isMenuopen, setIsMenuOpen] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchString, setSearchString] = React.useState('');


  const [searchResults, setSearchResults] = useState({ pages: [], news: [] });
  // const [searchResults, setSearchResults] = useState(makeSearchResultsForCategories(SearchResults.data));

  const [isTop, setIsTop] = useState(true)

  const [subMenuBool, setSubMenuBool] = React.useState(false);
  const [subMenuData, setSubMenuData] = React.useState({ tabData: [], title: '' });
  const [selectedSubMenuData, setSelectedSubMenuData] = React.useState({});
  const [subMenuSubDomain, setSubMenuSubDomain] = React.useState('');

  const [searchTabPagesBool, setSearchTabPagesBool] = useState(true);

  const [alertBool, setAlertBool] = useState(false);
  const [countrySiteDesc, setCountrySiteDesc] = useState({ name: "", url: "" });

  const [secondMenuBool, setSecondMenuBool] = useState(true);

  const InvestorsTabData = {
    title: 'INVESTORS',
    tabData: [
      {
        id: 1, name: "REPORTS & FILING", bgColor: '#fff', hashValue: 'reports-and-filing', subMenuTabData: [
          { id: 1, key: 'subMenuTabData1_1', name: 'Annual Report', bgColor: '#fff', hashValue: 'annual-report', },
          { id: 2, key: 'subMenuTabData1_2', name: 'Quarterly reports', bgColor: '#fff', hashValue: 'quarterly-reports', },
          { id: 3, key: 'subMenuTabData1_3', name: 'SEC Filing', bgColor: '#fff', hashValue: 'sec-filing', },
          { id: 4, key: 'subMenuTabData1_4', name: 'MoA and AoA', bgColor: '#fff', hashValue: 'moa-and-aoa', },
          { id: 5, key: 'subMenuTabData1_5', name: '37th Annual General Meeting', bgColor: '#fff', hashValue: 'annual-general-meeting', },
          { id: 6, key: 'subMenuTabData1_6', name: 'Sustainability Reports', bgColor: '#fff', hashValue: 'sustainability-reports', },
        ]
      },
      {
        id: 2, name: "INVESTOR MEET", bgColor: '#fff', hashValue: 'investor-meet', subMenuTabData: [
          { id: 1, key: 'subMenuTabData2_1', name: 'Earnings Call', bgColor: '#fff', hashValue: 'earnings-call', },
          { id: 2, key: 'subMenuTabData2_2', name: 'Investor Decks', bgColor: '#fff', hashValue: 'investor-decks', },
          { id: 3, key: 'subMenuTabData3_3', name: 'Investor Meeting Details', bgColor: '#fff', hashValue: 'investor-meeting-details', },
          { id: 4, key: 'subMenuTabData3_4', name: 'Investor Day 2022', bgColor: '#fff', hashValue: 'investor-day-2022', },
        ]
      },
      {
        id: 3, name: "SHARES", bgColor: '#fff', hashValue: 'shares', subMenuTabData: [
          { id: 1, key: 'subMenuTabData3_1', name: 'Share Price', bgColor: '#fff', hashValue: 'share-price', },
          { id: 2, key: 'subMenuTabData3_2', name: 'Equity & Dividend History', bgColor: '#fff', hashValue: 'equity-and-divident-history', },
          { id: 3, key: 'subMenuTabData3_3', name: 'Unpaid & Unclaimed Dividend', bgColor: '#fff', hashValue: 'unpaid-and-unclaimed-divident', },
          { id: 4, key: 'subMenuTabData3_4', name: 'Analyst Coverage', bgColor: '#fff', hashValue: 'analyst-coverage', },
          { id: 5, key: 'subMenuTabData3_5', name: 'Shareholding Pattern', bgColor: '#fff', hashValue: 'shareholding-pattern', },
          { id: 6, key: 'subMenuTabData3_6', name: 'Share Transfer Information', bgColor: '#fff', hashValue: 'share-transfer-information', },
          { id: 7, key: 'subMenuTabData3_7', name: 'Buyback', bgColor: '#fff', hashValue: 'buyback', },
          { id: 8, key: 'subMenuTabData3_8', name: 'IEPF Details', bgColor: '#fff', hashValue: 'details-of-shares', },
        ]
      },
      {
        id: 4, name: "GOVERNANCE", bgColor: '#fff', hashValue: 'governance', subMenuTabData: [
          { id: 1, key: 'subMenuTabData4_1', name: 'Internal Control Systems', bgColor: '#fff', hashValue: 'internal-control-systems', },
          { id: 2, key: 'subMenuTabData4_2', name: 'Committees of the Board', bgColor: '#fff', hashValue: 'committees-of-the-board', },
          { id: 3, key: 'subMenuTabData4_3', name: 'Code of Business Conduct and Ethics (COBE)', bgColor: '#fff', hashValue: 'code-of-business-conduct-and-ethics', },
          { id: 4, key: 'subMenuTabData4_4', name: 'Ombudsperson Reporting', bgColor: '#fff', hashValue: 'ombudsperson-reporting', url: 'https://secure.ethicspoint.eu/domain/media/en/gui/107510/index.html' },
          { id: 5, key: 'subMenuTabData4_5', name: 'Ombudsperson Policy', bgColor: '#fff', hashValue: 'ombudsperson-policy', },
          { id: 6, key: 'subMenuTabData4_6', name: 'Supplier Code of Conduct', bgColor: '#fff', hashValue: 'supplier-code-of-conduct', url: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/supplier-code-of-conduct-new.pdf` },
          { id: 7, key: 'subMenuTabData4_7', name: 'Compliance with NYSE', bgColor: '#fff', hashValue: 'compliance-with-nyse', },
          { id: 8, key: 'subMenuTabData4_8', name: 'Information to Stakeholders', bgColor: '#fff', hashValue: 'information-to-stakeholders', },
          { id: 9, key: 'subMenuTabData4_9', name: 'Corporate Governance Reports', bgColor: '#fff', hashValue: 'corporate-governance-reports', },
          { id: 10, key: 'subMenuTabData4_10', name: 'Policies and Documents', bgColor: '#fff', hashValue: 'policies-and-documents', },
          { id: 11, key: 'subMenuTabData4_11', name: 'Data Privacy Notice', bgColor: '#fff', hashValue: 'data-privacy-notice', url: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/data-privacy-notice.pdf` },
          { id: 12, key: 'subMenuTabData4_12', name: 'Certificates', bgColor: '#fff', hashValue: 'certificates' },
        ]
      },
      {
        id: 5, name: "INVESTOR SERVICES", bgColor: '#fff', hashValue: 'investor-services', subMenuTabData: [
          { id: 1, key: 'subMenuTabData5_1', name: 'Amalgamation', bgColor: '#fff', hashValue: 'amalgamation', },
          { id: 2, key: 'subMenuTabData5_2', name: 'Statutory Communication', bgColor: '#fff', hashValue: 'statutory-communication', },
          { id: 3, key: 'subMenuTabData5_3', name: 'Shareholder’s Contacts', bgColor: '#fff', hashValue: 'shareholders-contacts', },
          { id: 4, key: 'subMenuTabData5_4', name: 'Shareholder Information', bgColor: '#EDEDED', hashValue: 'shareholder-information', },
          // { id: 5, key: 'subMenuTabData5_5', name: 'Investor Meeting Details', bgColor: '#fff', hashValue: 'investor-meeting-details', },
          { id: 6, key: 'subMenuTabData5_6', name: 'Investor Handbook', bgColor: '#fff', hashValue: 'investor-handbook', },
          { id: 7, key: 'subMenuTabData5_7', name: 'Other Stock Exchange Intimations', bgColor: '#fff', hashValue: 'other-stock-exchange-intimations', },
          { id: 8, key: 'subMenuTabData5_8', name: 'Credit Rating', bgColor: '#fff', hashValue: 'credit-rating', },
        ]
      },
      {
        id: 6, name: 'ESG PROFILE', bgColor: '#fff', hashValue: 'esg-profile', subMenuTabData: [], url: 'https://bit.ly/3LkDIVJ'
      }
    ]
  }

  const ContactUsTabData = {
    title: 'CONTACT US',
    tabData: [
      { id: 1, name: "GLOBAL LOCATIONS", bgColor: '#fff', hashValue: 'global-locations', subMenuTabData: [] },
      { id: 2, name: "KEY CONTACTS", bgColor: '#fff', hashValue: 'key-contacts', subMenuTabData: [] },
      { id: 3, name: "MEDICAL INFORMATION", bgColor: '#fff', hashValue: 'medical-information', subMenuTabData: [] },
    ]
  }

  const BusinessPartnerTabData = {
    title: 'BUSINESS PARTNERS',
    tabData: [
      { id: 1, name: "PROSPECTIVE PARTNERS", bgColor: '#fff', hashValue: 'prospective-partners', subMenuTabData: [] },
      { id: 2, name: "POST AN ENQUIRY", bgColor: '#ededed', hashValue: 'post-an-enquiry', subMenuTabData: [] },
      { id: 3, name: "LOGIN FOR CURRENT PARTNERS", bgColor: '#fff', hashValue: 'login-for-current-partner', subMenuTabData: [] },
    ]
  }

  const CountrySitesTabData = {
    title: 'COUNTRY SITES',
    tabData: [
      { id: 1, name: "Australia", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.australia },
      { id: 2, name: "Austria", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.austria },
      { id: 2.1, name: "Brazil", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.brazil },
      { id: 3, name: "Canada", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.cananda },
      { id: 4, name: "Chile", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.chile },
      { id: 5, name: "China", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.china },
      { id: 5.1, name: "Colombia", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.colombia },
      { id: 5.2, name: "Czech Republic", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.czech },
      { id: 5.3, name: "Denmark", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.denmark },
      { id: 6, name: "Finland", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.finland },
      { id: 6.1, name: "France", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.france },
      { id: 7, name: "Germany", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.germany },
      { id: 8, name: "India", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.india },
      { id: 9, name: "Italy", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.italy },
      { id: 10, name: "Netherlands", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.netherlands },
      { id: 11, name: "New Zealand", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.new_zealand },
      { id: 11, name: "Portugal", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.portugal },
      { id: 12, name: "Romania", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.romania },
      { id: 13, name: "Russia", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.russia },
      { id: 14, name: "South Africa", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.southAfrica },
      { id: 15, name: "Spain", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.spain },
      { id: 15.1, name: "Slovakia", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.slovakia },
      { id: 15.2, name: "Sweden", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.sweden },
      { id: 16, name: "Ukraine", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.ukraine },
      { id: 17, name: "United Kingdom", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.uk },
      { id: 18, name: "United States", bgColor: '#fff', hashValue: '', subMenuTabData: [], url: config.countrySites.us },
    ]
  }

  const MediaTabData = {
    title: 'MEDIA',
    tabData: [
      {
        id: 1, name: "PRESS RELEASES", bgColor: '#fff', hashValue: 'press-releases',
      },
      {
        id: 2, name: "PRESS PRESENTATIONS", bgColor: '#fff', hashValue: 'press-presentations',
      },
      {
        id: 3, name: "MEDIA KIT", bgColor: '#fff', hashValue: 'media-kit',
      },
    ]
  }

  const ViewStaticData = [
    {
      id: 'ViewStaticData_1',
      href: '/',
      name: 'Home',
      subMenu: [
        { id: 'ViewStaticData_1_subMenu_1', name: 'About Us', hashName: '#about-us' },
        { id: 'ViewStaticData_1_subMenu_2', name: 'Building A Sustainable Future', hashName: '#sustainability' },
        { id: 'ViewStaticData_1_subMenu_3', name: '#WeAreDrReddys', hashName: '#we-are-drreddys' },
        { id: 'ViewStaticData_1_subMenu_4', name: 'Latest News', hashName: '#we-are-in-the-news' },

      ],    },
    {
      id: 'ViewStaticData_2',
      href: '/who-we-are',
      name: 'Who We Are',
      subMenu: [
        { id: 'ViewStaticData_2_subMenu_1', name: 'Purpose', hashName: '#purpose' },
        { id: 'ViewStaticData_2_subMenu_2', name: 'Promises', hashName: '#promises' },
        { id: 'ViewStaticData_2_subMenu_3', name: 'Principles', hashName: '#principles' },
        { id: 'ViewStaticData_2_subMenu_4', name: 'Values', hashName: '#values' },
        { id: 'ViewStaticData_2_subMenu_5', name: 'Leadership', hashName: '#leadership' },
        { id: 'ViewStaticData_2_subMenu_6', name: 'Our Founder', hashName: '#our-founder' },
        { id: 'ViewStaticData_2_subMenu_7', name: 'Our Journey', hashName: '#Journey-so-far' },
      ],
    },
    {
      id: 'ViewStaticData_3',
      href: '/business-and-capabilities',
      name: 'Our Businesses',
      subMenu: [
        { id: 'ViewStaticData_3_subMenu_1', name: 'Our Business', hashName: '#our-businesses' },
        { id: 'ViewStaticData_3_subMenu_2', name: 'Our Capabilities', hashName: '#our-capabilities' },
        { id: 'ViewStaticData_3_subMenu_3', name: 'Stories', hashName: '#stories' },
        { id: 'ViewStaticData_3_subMenu_4', name: 'Jobs', hashName: '#jobs' },
        { id: 'ViewStaticData_3_subMenu_5', name: 'Contact Us', hashName: '#contact-us' },
      ],
    },
    {
      id: 'ViewStaticData_4',
      href: '/business-responsibility-and-sustainability',
      name: 'Sustainability',
      subMenu: [
        { id: 'ViewStaticData_4_subMenu_1', name: 'Milestones', hashName: '#milestones' },
        { id: 'ViewStaticData_4_subMenu_2', name: 'Our ESG Goals', hashName: '#esg-goals' },
        { id: 'ViewStaticData_4_subMenu_3', name: 'Reports and Disclosures', hashName: '#disclosures' },
        { id: 'ViewStaticData_4_subMenu_4', name: 'Community Initiatives', hashName: '#community-initiatives' },
        { id: 'ViewStaticData_4_subMenu_5', name: 'Employee Volunteering', hashName: '#volunteering' },
      ],
    },
    // {
    //   id: 'ViewStaticData_5',
    //   href: '/PeopleStories',
    //   name: 'Stakeholders And Stories',
    //   subMenu: [
    //     { id: 'ViewStaticData_5_subMenu_1', name: 'Patient Stories', hashName: '#patient-stories' },
    //     { id: 'ViewStaticData_5_subMenu_2', name: 'Patient Programmes', hashName: '#patient-programmes' },
    //     { id: 'ViewStaticData_5_subMenu_3', name: 'HCP Stories', hashName: '#hcp-stories' },
    //     { id: 'ViewStaticData_5_subMenu_4', name: 'Partner Stories', hashName: '#partner-stories' },
    //     { id: 'ViewStaticData_5_subMenu_5', name: 'Be Our Partner', hashName: '#be-our-partner' },
    //   ],
    // },
    {
      id: 'ViewStaticData_6',
      href: '/LifeAtDrReddys',
      name: 'Life at Dr. Reddy’s',
      subMenu: [
        { id: 'ViewStaticData_6_subMenu_1', name: 'Our Culture', hashName: '#our-culture' },
        { id: 'ViewStaticData_6_subMenu_2', name: 'ASPIRE Framework', hashName: '#aspire-framework' },
        { id: 'ViewStaticData_6_subMenu_3', name: 'Our People', hashName: '#our-people' },
        { id: 'ViewStaticData_6_subMenu_4', name: 'Awards', hashName: '#awards' },
        { id: 'ViewStaticData_6_subMenu_5', name: 'Capability Building Programmes', hashName: '#employee-enrichment-programmes' },
        { id: 'ViewStaticData_6_subMenu_6', name: 'Work With Us', hashName: '#work-with-us' },
      ],
    },
  ]

  // const startAnimation = (entries, observer) => {
  //   entries.forEach(entry => {
  //     entry.target.classList.toggle("slide-in-from-bottom", entry.isIntersecting);
  //     if (entry.intersectionRatio > 0) {
  //       observer.unobserve(entry.target);
  //     }
  //   });
  // };





  useEffect(() => {

    document.addEventListener("scroll", () => {
      const scrollCheck = (window.scrollY < 300)
      if (scrollCheck !== isTop) {
        setIsTop(scrollCheck)
      }
    })


    // const observer = new IntersectionObserver(startAnimation);
    // const options = { root: null, rootMargin: '0px', threshold: 1 };
    // const elements = document.querySelectorAll('.animation');

    // elements.forEach(el => {
    //   observer.observe(el, options);
    // });

  })

  function openModal() {
    if (modalIsOpen) {
      document.body.style.overflow = "scroll"
    } else {
      document.body.style.overflow = "hidden"
    }
    setSearchTabPagesBool(true);
    setIsOpen(true);
  }

  function closeModal() {
    if (modalIsOpen) {
      document.body.style.overflow = "scroll"
    } else {
      document.body.style.overflow = "hidden"
    }
    setSearchString('');
    setSearchResults({ pages: [], news: [] });
    setIsOpen(false);
  }


  const SearchInput = styled.input`
    flex:1;
    background-color:transparent;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-color: #FF5046;
    height:60px;
    letter-spacing:0;
    color:#fff !important;
    font-size:40px !important;
    font-weight:200 !important;
    padding-bottom:20px;
    -webkit-border-radius:0;
    border-radius:0;
    -webkit-appearance: none;
    &:focus {
      outline: none;
    }
    @media (max-width: 900px) {
      font-size:24px !important;
      height:55px;
    }
  `;

  const ScrollDiv = styled.div`
     display: flex;
     flex: 1;
     marginTop: 5%;
     flexDirection: column;
     overflow: 'scroll';
     /* &::-webkit-scrollbar {
       width: 10px;
       height: 10px;
      } */
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
      }
      @media (max-width: 820px) {
      margin-bottom:60px;
    }
  `;

  const SearchResultDesc = styled.p`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
    max-height:5em;
    -webkit-box-orient: vertical;
    color: #fff;
    fontSize: 18px;
    fontWeight: 200;
    margin:0;
    @media (max-width: 820px) {
     max-height:4em;
   }
    @media (min-width: 767px) and (max-width: 820px) {
     max-height:5em;
   }
  `;

  const UnderlineTag = styled.a`
  border-bottom: 1px solid transparent;
    &:hover{
      border-bottom: 1px solid #000000;
    }
`;

  const truncateString = (str, n) => {
    if (str.length > n) {
      return str.substring(0, n) + "...";
    } else {
      return str;
    }

  }

  const searchApiTrigger = (searchStr) => {
    setSearchString(searchStr.target.value);
    if (searchStr.target.value && searchStr.target.value.length > 2) {
      axios({ method: "get", url: config.serverUrl.base + "/jsonapi/index/search_pages?filter[fulltext]=" + searchStr.target.value, })
        .then((res) => {
          setSearchResults(makeSearchResultsForCategories(res.data.data));
        })
        .catch((Err) => {
          setSearchResults({ pages: [], news: [] });
          console.log(Err, "-=-=-=  ERR")
        })
    } else {
      setSearchResults({ pages: [], news: [] });
    }
  }

  // var navData = sessionStorage.getItem("navPath");
  // var tempNav = "";
  // if (navData) {
  //   tempNav = JSON.parse(navData).id;
  // }

  const renderMenuContent = () => {
    let burgerMenuId = "83343092-5fee-32e4-6c13-ebcf23f25d17";
    let burgerMenu1ClassName = "af-class-menu-bar af-class-_1";
    let burgerMenu2ClassName = "af-class-menu-bar af-class-_2";
    return <div style={{ width: '100%' }} >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '40px' }} >
        {/* {subMenuBool && <a
          onClick={() => {
            setSubMenuBool(false);
            setSubMenuData([]);
          }} aria-currenloadingt="page" className="af-class-nav-logo w-inline-block w--current" style={{ display: "flex", flexDirection: 'row', cursor: "pointer" }} >
          <img  loading="lazy" src={BackIconPrimary} style={{ height: 20, width: 20 }} />
        </a>
        } */}
        {props.backButton
          ?
          <a
            href={props.onBackNavPath ? props.onBackNavPath : "/"}
            onClick={(ev) => {
              // if (tempNav && tempNav.length !== 0) {
              // sessionStorage.removeItem("navPath");
              // } else {
              if (!isMenuopen) {
                ev.preventDefault();
                history.goBack()
              }
              // }
            }} aria-current="page" className="af-class-nav-logo w-inline-block w--current" style={{ display: "flex", flexDirection: 'row', cursor: "pointer" }} >
            {isMenuopen
              ?
              <img src="/images/DrR_Logo_Primary_RGB.svg" loading="lazy" alt className="af-class-logo-purple" />
              :
              <img  loading="lazy" src={BackIconPrimary} style={{ height: 20, width: 20 }} />
            }
            {/* <h5 style={{ margin: 0, fontWeight: "600", marginLeft: "5px", fontSize: "18px", color: "#5225b5" }} >Back</h5> */}</a>
          :
          <a href="/" aria-current="page" className="af-class-nav-logo w-inline-block w--current"><img src="/images/DrR_Logo_Primary_RGB.svg" style={{ opacity: 1 }} loading="lazy" alt /></a>
        }
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
          <a href='/investor' style={{ marginRight: '12px', textDecoration: 'none', alignItems: 'center', color: '#909090', fontSize: '14px', fontWeight: '400' }} >INVESTORS</a>
          <a href='/drreddys-media' style={{ marginRight: '12px', textDecoration: 'none', alignItems: 'center', color: '#909090', fontSize: '14px', fontWeight: '400' }} >MEDIA</a>
          <a href='/business-partner' style={{ marginRight: '12px', textDecoration: 'none', alignItems: 'center', color: '#909090', fontSize: '14px', fontWeight: '400' }} >PARTNERS</a>
          <a href='/job-seeker' style={{ marginRight: '12px', textDecoration: 'none', alignItems: 'center', color: '#909090', fontSize: '14px', fontWeight: '400' }} >CAREERS</a>
          <a href='/contact-us' style={{ marginRight: '12px', textDecoration: 'none', alignItems: 'center', color: '#909090', fontSize: '14px', fontWeight: '400' }} >CONTACT US</a>
          <div style={{ border: '1px solid #909090', marginLeft: '10px', marginRight: '15px', height: '21px', marginBottom: '3px' }} />
          <a href='#' onMouseOver={() => {
            setCountrySiteBool(true);
          }} style={{ marginRight: '12px', borderBottomColor: countrySiteBool ? '#FF5046' : 'transparent', borderBottomWidth: '3px', borderBottomStyle: 'solid', }} >
            <img  loading="lazy" src="/images/globe-icon.svg" style={{ height: '20px', marginBottom: '2px' }} />
          </a>
          <a href='#' onClick={openModal} style={{}} >
            <img  loading="lazy" src="/images/search-feather.svg" style={{ height: '20px', marginBottom: '2px', marginTop: '-3px' }} />
          </a>
        </div>
      </div>
      {
        countrySiteBool && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: '30px', paddingBottom: '30px' }}
          onMouseLeave={()=>{
          setCountrySiteBool(false);
        }} >
          <h5 style={{ fontSize: '32px', color: '#000', fontWeight: 300, whiteSpace: 'nowrap', marginRight: '150px' }} >Country Sites</h5>

          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', flex: '1 1 280px', height: '120px'}} >
            {CountrySitesTabData.tabData.map((item, index) => {
              return <div style={{alignSelf:'stretch'}} >
              <a className="hover-underline-animation" style={{textDecoration: 'none', alignItems: 'center', color: '#6A6A6A', fontSize: '14px', fontWeight: 300, marginBottom: '8px', marginRight: '10px'}} key={item.id + ": " + index} href={item.url}
              onClick={(ev) => {
                  ev.preventDefault();
                  // document.getElementsByClassName("af-class-menu-button")[0].click();
                  setAlertBool(true);
                  setCountrySiteDesc({ name: item.name.toUpperCase(), url: item.url })
                }}
              >{item.name.toUpperCase()}</a>
              </div>
            })}
          </div>

          {/* <div style={{ display: 'grid', gridTemplateRows: '3fr 3fr 3fr', gridTemplateColumns: '2fr 2fr 2fr 2fr 2fr 2fr 2fr', gridAutoFlow: 'column', flex: 1}} >
            {CountrySitesTabData.tabData.map((item, index) => {
              return <div style={{alignSelf:'stretch'}} > */}
              {/* <UnderlineTag key={item.id + ": " + index} href={item.url} style={{ textDecoration: 'none', alignItems: 'center', color: '#6A6A6A', fontSize: '14px', fontWeight: 'normal' }} >{item.name}</UnderlineTag> */}
              {/* <a className="hover-underline-animation" style={{textDecoration: 'none', alignItems: 'center', color: '#6A6A6A', fontSize: '14px', fontWeight: 'light'}} key={item.id + ": " + index} href={item.url} >{item.name.toUpperCase()}</a>

              </div>
            })}
          </div> */}
        </div>
      }
      {!countrySiteBool && <div style={{ flexDirection: 'row', alignItems: 'center' }} ref={divParentRef} >
        {
          ViewStaticData.map((item, index) => {
            return <a key={item.id + ": " + index} id={item.id + ": " + index} onMouseOver={() => {
              if (index === 1 || index === 2 || index === 0) {
                setGridFrameNum(2);
              } else if (index === 3) {
                setGridFrameNum(1);
              } else {
                setGridFrameNum(0);
              }
              setSelectedView({ ...item })
              setSelectedViewMargin(document.getElementById(item.id + ": " + index).getBoundingClientRect().x - divParentRef.current.getBoundingClientRect().x)
            }} href={item.subMenu && item.subMenu.length !== 0 ? item.href : "#"} style={{ marginRight: '30px', textDecoration: 'none', alignItems: 'center', color: window.location.pathname === item.href ? '#0F0F0F' : '#4D4D4D', fontSize: '16px', borderBottomColor: selectedView.id === item.id ? '#FF5046' : 'transparent', borderBottomWidth: '3px', borderBottomStyle: 'solid', fontWeight: window.location.pathname === item.href ? 'bold' : 'normal' }} >{item.name}</a>
          })
        }
      </div>}
      {selectedView?.subMenu && selectedView?.subMenu.length !== 0 && !countrySiteBool && <div style={{ marginTop: '20px', marginLeft: `${selectedViewMargin}px`, display: 'grid', gridTemplateRows: '3fr 3fr 3fr', gridTemplateColumns: `1fr 1fr ${gridFrameNum}fr`, gridAutoFlow: 'column' }}
      onMouseEnter={() => {}}
      onMouseLeave={() => {
        setSelectedView({});
        setSelectedViewMargin(0);
      }} >
        {/* <div style={{ position: 'absolute', right: 0 }} onClick={() => {
          setSelectedView({});
          setSelectedViewMargin(0);
        }} >
          <img  loading="lazy" src={'/images/icons8-close-60.png'} style={{ height: '15px', }} />
        </div> */}
        {
          selectedView.subMenu.map((item, index) => {
            return <div style={{alignSelf:'stretch',marginBottom:'10px',}} >
            {/* <UnderlineTag key={item.id + ": " + index} href={selectedView.href + item.hashName} style={{ flex: 1, textDecoration: 'none', alignItems: 'center', color: '#000', fontSize: '18px', fontWeight: '300',whiteSpace: 'nowrap' }} >{item.name}</UnderlineTag> */}
            <a className="hover-underline-animation" style={{textDecoration: 'none'}} key={item.id + ": " + index} href={selectedView.href + item.hashName} >{item.name}</a>
            </div>
          })
        }
      </div>}
    </div>
  }


  const renderMobileMenuContent = () => {
    var burgerMenuId = "";
    var burgerMenu1ClassName = "";
    var burgerMenu2ClassName = "";
    if (props.logoColor === "white" && isTop) {
      burgerMenuId = "c9d27274-7db2-4e60-1027-149ce1aa5728";
      burgerMenu1ClassName = "af-class-menu-bar af-class-_1 af-class-white";
      burgerMenu2ClassName = "af-class-menu-bar af-class-_2 af-class-white";
    } else if (!isTop) {
      burgerMenuId = "c9d27274-7db2-4e60-1027-149ce1aa5728";
      burgerMenu1ClassName = "af-class-menu-bar af-class-_1 af-class-white af-class-primary";
      burgerMenu2ClassName = "af-class-menu-bar af-class-_2 af-class-white af-class-primary";
    } else {
      burgerMenuId = "83343092-5fee-32e4-6c13-ebcf23f25d17";
      burgerMenu1ClassName = "af-class-menu-bar af-class-_1";
      burgerMenu2ClassName = "af-class-menu-bar af-class-_2";
    }
    if (props.logoColor === "white" && isTop) {
      return <>
        {/* {subMenuBool && <a
          onClick={() => {
            setSubMenuBool(false);
            setSubMenuData([]);
          }} aria-current="page" className="af-class-nav-logo w-inline-block w--current" style={{ display: "flex", flexDirection: 'row', cursor: "pointer" }} >
          <img  loading="lazy" src={BackIconPrimary} style={{ height: 20, width: 20 }} />
        </a>
        } */}
        {props.backButton
          ?
          <a style={{ cursor: "pointer", marginBottom: isMenuopen ? '0px' : '20px' }}
            // href={props.onBackNavPath ? props.onBackNavPath : "#"}
            href="/"
            onClick={(ev) => {
              // if (tempNav && tempNav.length !== 0) {
              // sessionStorage.removeItem("navPath");
              // } else {
              if (!isMenuopen) {
                ev.preventDefault();
                history.goBack();
              }
              // }
            }} className="af-class-nav-logo w-inline-block" >
            {isMenuopen
              ?
              <img src="/images/DrR_Logo_Primary_RGB.svg" loading="lazy" alt className="af-class-logo-purple" />
              :
              <span className="af-class-logo-white" style={{ display: "flex", flexDirection: 'row' }} >
                <img loading="lazy" src={BackIcon} style={{ height: 20, width: 20 }} />
                {/* <h5 style={{ margin: 0, fontWeight: "600", marginLeft: "5px", fontSize: "18px", color: "#fff" }} >Back</h5> */}
              </span>
            }
          </a>
          :
          <a href="/" className="af-class-nav-logo w-inline-block">
            <img src="/images/DrR_Logo_Primary_RGB.svg" style={{ opacity: 0 }} loading="lazy" alt className="af-class-logo-purple" />
            <img src="/images/Dr._Reddys_Laboratories_Logo.svg" loading="lazy" alt className="af-class-logo-white" />
          </a>
        }
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
          <a href='#' onClick={openModal} style={{ marginRight: '15px' }} >
            <img src="/images/Icon-feather-search-FFF.svg" style={{ height: '25px' }}  loading="lazy" />
          </a>
          <div className="af-class-menu-options" onClick={() => {
            if (isMenuopen) {
              document.body.style.overflow = "scroll"
            } else {
              document.body.style.overflow = "hidden"
            }
            setIsMenuOpen(!isMenuopen)
            setSubMenuBool(false);
            setSubMenuData({ tabData: [], title: '' });
          }}>
            <div data-w-id={burgerMenuId} className="af-class-menu-button">
              <div className={burgerMenu1ClassName} style={{ borderRadius: '1.2em' }} />
              <div className={burgerMenu2ClassName} style={{ borderRadius: '1.2em' }} />
            </div>
          </div>
        </div>
      </>
    } else {
      return <>
        {/* {subMenuBool && <a
          onClick={() => {
            setSubMenuBool(false);
            setSubMenuData([]);
          }} aria-current="page" className="af-class-nav-logo w-inline-block w--current" style={{ display: "flex", flexDirection: 'row', cursor: "pointer" }} >
          <img  loading="lazy" src={BackIconPrimary} style={{ height: 20, width: 20 }} />
        </a>
        } */}
        {props.backButton
          ?
          <a
            href={props.onBackNavPath ? props.onBackNavPath : "/"}
            onClick={(ev) => {
              // if (tempNav && tempNav.length !== 0) {
              // sessionStorage.removeItem("navPath");
              // } else {
              if (!isMenuopen) {
                ev.preventDefault();
                history.goBack()
              }
              // }
            }} aria-current="page" className="af-class-nav-logo w-inline-block w--current" style={{ display: "flex", flexDirection: 'row', cursor: "pointer" }} >
            {isMenuopen
              ?
              <img src="/images/DrR_Logo_Primary_RGB.svg" loading="lazy" alt className="af-class-logo-purple" />
              :
              <img src={BackIconPrimary} style={{ height: 20, width: 20 }}  loading="lazy"/>
            }
            {/* <h5 style={{ margin: 0, fontWeight: "600", marginLeft: "5px", fontSize: "18px", color: "#5225b5" }} >Back</h5> */}</a>
          :
          <a href="/" aria-current="page" className="af-class-nav-logo w-inline-block w--current"><img src="/images/DrR_Logo_Primary_RGB.svg" style={{ opacity: 1 }} loading="lazy" alt /></a>
        }
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
          <a href='#' onClick={openModal} style={{ marginRight: '15px' }} >
            <img  loading="lazy" src="/images/Icon-feather-search-primary.svg" style={{ height: '25px' }} />
          </a>
          <div className="af-class-menu-options" onClick={() => {
            if (isMenuopen) {
              document.body.style.overflow = "scroll"
            } else {
              document.body.style.overflow = "hidden"
            }
            setIsMenuOpen(!isMenuopen)
            setSubMenuBool(false);
            setSubMenuData({ tabData: [], title: '' });
          }}>
            <div data-w-id={burgerMenuId} className="af-class-menu-button">
              <div className={burgerMenu1ClassName} style={{ borderRadius: '1.2em' }} />
              <div className={burgerMenu2ClassName} style={{ borderRadius: '1.2em' }} />
            </div>
          </div>
        </div>
      </>
    }
  }



  return <>

    <CAlert enableAlert={alertBool} onClose={() => { setAlertBool(false) }} countrySiteDesc={countrySiteDesc} />

    {modalIsOpen && <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999999999, backgroundColor: 'rgba(0,0,0,0.9)', height: '100vh', width: '100vw' }} >
      <div className='af-class-section' style={{ backgroundColor: 'transparent', height: '100%', width: '100%', marginBottom: '0px', paddingBottom: '0px', overflow: 'auto' }} >
        <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container" style={{ backgroundColor: 'transparent', height: '100%', width: '100%', marginBottom: '0px', paddingBottom: '0px' }} >
          <a onClick={closeModal} style={{ alignItems: 'center', justifyContent: 'center', justifyItems: 'center', position: 'absolute', top: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? -30 : -90, right: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 0 : 10, cursor: 'pointer' }} >
            <img  loading="lazy" src={CloseIconFFF} style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : '24px', }} />
          </a>
          <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', flexDirection: 'column' }} >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
              <SearchInput autoFocus id="search_input_field" placeholder='Search' onChange={searchApiTrigger} value={searchString} />
            </div>
            {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && (searchResults.news.length !== 0 || searchResults.pages.length !== 0) && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '20px', marginBottom: '40px' }} >
              <h4 onClick={() => {
                document.getElementById("search_input_field").blur();
                setSearchTabPagesBool(true)
                document.getElementById("search_input_field").blur();
              }} style={{ color: '#fff', fontSize: '18px', fontWeight: searchTabPagesBool ? 'bold' : '200', padding: 0, margin: 0, textDecoration: searchTabPagesBool ? 'underline' : 'none', textDecorationColor: searchTabPagesBool ? '#ff5046' : 'transparent', textUnderlineOffset: '12px', }} >PAGES</h4>
              <h4 onClick={() => {
                document.getElementById("search_input_field").blur();
                setSearchTabPagesBool(false)
                document.getElementById("search_input_field").blur();
              }} style={{ color: '#fff', fontSize: '18px', fontWeight: !searchTabPagesBool ? 'bold' : '200', padding: 0, margin: 0, textDecoration: !searchTabPagesBool ? 'underline' : 'none', textDecorationColor: !searchTabPagesBool ? '#ff5046' : 'transparent', textUnderlineOffset: '12px', }} >NEWS</h4>
            </div>}
            {
              window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div
                style={{ overflow: 'scroll' }}
              >
                {searchResults?.[searchTabPagesBool ? "pages" : 'news'] && searchResults[searchTabPagesBool ? "pages" : 'news'].map((item, index) => {
                  return <a
                    id={index === 0 ? "mobileSearchResults" : ""}
                    onClick={() => {
                      getNewsResultsUrl(item);
                    }} style={{ cursor: 'pointer', textDecoration: 'none' }} >
                    <div
                      // className="animation"
                      style={{ marginBottom: '40px', }}>
                      <h5 className='af-class-headlines-heading' style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', lineHeight: '25px', margin: 0, padding: 0, marginBottom: '15px' }} >{item.attributes.title}</h5>
                      {/* <div className="af-class-menu-link-underline-white"/> */}
                      <SearchResultDesc className='af-class-richtext-custom' style={{ color: '#fff', fontSize: '18px', fontWeight: '200', lineHeight: '30px' }}
                      // dangerouslySetInnerHTML={{
                      //   __html: item.attributes?.field_content?.processed ? item.attributes.field_content.processed : ""
                      // }}
                      >{item.attributes?.field_content?.processed ? item.attributes.field_content.processed.replace(/<\/?[^>]+>/gi, ' ') : ""}</SearchResultDesc>
                    </div>
                  </a>
                })}
                <div style={{ minHeight: '100px' }}
                ></div>

              </div>

            }
            {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && (searchResults.news.length !== 0 || searchResults.pages.length !== 0) && <ScrollDiv style={{ display: 'flex', flex: 1, marginTop: '5%', flexDirection: 'column' }} >
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', justifyContent: 'space-between' }} >
                {searchResults?.pages && searchResults.pages.length !== 0 && <div style={{ width: searchResults?.news && searchResults.news.length !== 0 ? '50%' : '70%', paddingRight: searchResults?.news && searchResults.news.length !== 0 ? '15%' : 0 }} >
                  {searchResults?.pages && searchResults.pages.length !== 0 && <h4 style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: 0, margin: 0, textDecoration: 'underline', textDecorationColor: '#ff5046', textUnderlineOffset: '12px', marginBottom: '40px' }} >PAGES</h4>}
                  {searchResults?.pages && searchResults.pages.map((item, index) => {
                    return <a
                      onClick={() => {
                        getNewsResultsUrl(item);
                      }} style={{ cursor: 'pointer', textDecoration: 'none' }} >
                      <div
                        // className="animation"
                        style={{ marginBottom: '40px', }}>
                        <h5 className='af-class-headlines-heading' style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', lineHeight: '25px' }} >{item.attributes.title}</h5>
                        {/* <div className="af-class-menu-link-underline-white"/> */}
                        <SearchResultDesc className='af-class-richtext-custom' style={{ color: '#fff', fontSize: '18px', fontWeight: '200', lineHeight: '30px' }}
                        // dangerouslySetInnerHTML={{
                        //   __html: item.attributes?.field_content?.processed ? item.attributes.field_content.processed : ""
                        // }}
                        >{item.attributes?.field_content?.processed ? item.attributes.field_content.processed.replace(/<\/?[^>]+>/gi, ' ') : ""}</SearchResultDesc>
                      </div>
                    </a>
                  })}
                </div>}
                {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && searchResults?.news && searchResults.news.length !== 0 && <div style={{ width: searchResults?.pages && searchResults.pages.length !== 0 ? '50%' : '70%', paddingRight: searchResults?.pages && searchResults.pages.length !== 0 ? '15%' : 0 }} >
                  {searchResults?.news && searchResults.news.length !== 0 && <h4 style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: 0, margin: 0, textDecoration: 'underline', textDecorationColor: '#ff5046', textUnderlineOffset: '12px', marginBottom: '40px' }} >NEWS</h4>}
                  {searchResults?.news && searchResults.news.map((item, index) => {
                    return <a
                      onClick={() => {
                        getNewsResultsUrl(item);
                      }} style={{ cursor: 'pointer', textDecoration: 'none' }} >
                      <div
                        // className="animation"
                        style={{ marginBottom: '40px', }} >
                        <h5 className='af-class-headlines-heading' style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', lineHeight: '25px' }} >{item.attributes.title}</h5>
                        <SearchResultDesc className='af-class-richtext-custom' style={{ color: '#fff', fontSize: '18px', fontWeight: '200', lineHeight: '30px' }}
                        // dangerouslySetInnerHTML={{
                        //   __html: item.attributes?.field_content?.processed ? item.attributes.field_content.processed : ""
                        // }}
                        >{item.attributes?.field_content?.processed ? item.attributes.field_content.processed.replace(/<\/?[^>]+>/gi, ' ') : ""}</SearchResultDesc>
                      </div>
                    </a>
                  })}
                </div>}
              </div>
            </ScrollDiv>}

          </div>
        </div>
      </div>
    </div>}

    <nav id="Navbar" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d12" className={"af-class-navbar"}>
      <div className={"af-class-navbar-container"} onMouseLeave= {() => {
        setSelectedView({});
        setSelectedViewMargin(0);
      }}>
        {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? renderMobileMenuContent() : renderMenuContent()}

        <div className="af-class-nav-menu-wrapper" style={{ overflow: 'auto' }} >
          {subMenuBool && <div style={{ display: 'flex', position: 'absolute', zIndex: 999, top: '8%', backgroundColor: '#EDEDED', height: '92%', flexDirection: 'column', width: '80%' }}>
            <a onClick={() => {
              setSubMenuBool(false);
              setSubMenuData({ tabData: [], title: '' });
            }} >
              <img style={{ marginTop: '40px', marginBottom: '30px' }} loading="lazy" src={PrimaryBackFullIcon} />
            </a>
            <a key={"SubMenuTitle" + subMenuData.title} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row' }} href={subMenuSubDomain} onClick={(ev) => {
              if (subMenuSubDomain === '/country-sites') {
                ev.preventDefault();
              }
            }} >
              {/* <div style={{ marginRight: selectedSubMenuData.name === subMenuItem.name ? '13px' : '20px', width: '11px' }} >
                        <img loading="lazy" src={selectedSubMenuData.name === subMenuItem.name?'/images/accordianOpenPrimary.png':'/images/accordianPrimary.png'} />
                      </div> */}
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '25px', color: '#000' }} >{subMenuData.title}</p>
            </a>
            <div style={{ display: subMenuSubDomain === '/country-sites' && 'flex', flexWrap: subMenuSubDomain === '/country-sites' && 'wrap' }} >
              {subMenuData.tabData.map((subMenuItem, subMenuIndex) => {
                if (subMenuItem.hasOwnProperty('url')) {
                  return <a key={"SubMenu" + subMenuIndex} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row', width: subMenuSubDomain === '/country-sites' && '50%' }} target="_blank" href={subMenuItem.url}
                    onClick={(ev) => {
                      if (subMenuSubDomain === '/country-sites') {
                        ev.preventDefault();
                        document.getElementsByClassName("af-class-menu-button")[0].click();
                        setAlertBool(true);
                        setCountrySiteDesc({ name: subMenuItem.name.toUpperCase(), url: subMenuItem.url })
                      }
                    }}
                  >
                    {/* <div style={{ marginRight: selectedSubMenuData.name === subMenuItem.name ? '13px' : '20px', width: '11px' }} >
                        <img  loading="lazy" src={selectedSubMenuData.name === subMenuItem.name?'/images/accordianOpenPrimary.png':'/images/accordianPrimary.png'} />
                      </div> */}
                    <p style={{ fontSize: '16px', fontWeight: '300', marginBottom: '20px', color: selectedSubMenuData.name === subMenuItem.name ? '#5224B5' : '#000', textTransform: subMenuSubDomain === '/country-sites' && 'uppercase' }} >{subMenuItem.name}</p>
                  </a>
                } else {
                  return <a key={"SubMenu" + subMenuIndex} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row', width: subMenuSubDomain === '/country-sites' && '50%' }} href={subMenuData.title === "INVESTORS" ? '/investor' + "#" + subMenuItem.hashValue : '/detail-sub-menu' + "#" + subMenuItem.hashValue} onClick={() => {
                    window.location.href = '/detail-sub-menu#' + subMenuItem.hashValue;
                    window.location.reload();
                  }} >
                    {/* <div style={{ marginRight: selectedSubMenuData.name === subMenuItem.name ? '13px' : '20px', width: '11px' }} >
                        <img  loading="lazy" src={selectedSubMenuData.name === subMenuItem.name?'/images/accordianOpenPrimary.png':'/images/accordianPrimary.png'} />
                      </div> */}
                    <p style={{ fontSize: '16px', fontWeight: '300', marginBottom: '20px', color: selectedSubMenuData.name === subMenuItem.name ? '#5224B5' : '#000', textTransform: subMenuSubDomain === '/country-sites' && 'uppercase' }} >{subMenuItem.name}</p>
                  </a>
                }
                {/* else {
                    return <>
                      <a key={"SubMenu" + subMenuIndex} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row' }} onClick={(ev) => {
                        if (subMenuItem.subMenuTabData.length !== 0 && selectedSubMenuData.name !== subMenuItem.name) {
                          setSelectedSubMenuData(subMenuItem);
                        } else if (selectedSubMenuData.name === subMenuItem.name) {
                          setSelectedSubMenuData({});
                        }
                      }} >
                        <div style={{ marginRight: selectedSubMenuData.name === subMenuItem.name ? '13px' : '20px' }} >
                          <img loading="lazy" src={selectedSubMenuData.name === subMenuItem.name ? '/images/accordianOpenPrimary.png' : '/images/accordianPrimary.png'} />
                        </div>
                        <div style={{ flex: 1 }} >
                          <p style={{ fontSize: '16px', fontWeight: '300', marginBottom: '20px', color: selectedSubMenuData.name === subMenuItem.name ? '#5224B5' : '#000' }} >{subMenuItem.name}</p>
                        </div>
                      </a>
                      <div style={{ marginLeft: selectedSubMenuData.name === subMenuItem.name ? '24px' : '31px' }} >
                        {selectedSubMenuData.name === subMenuItem.name && subMenuItem.subMenuTabData.map((sItem, sIndex) => {
                          if (sItem.hasOwnProperty("url") && sItem.url.length !== 0) {
                            return <a key={"SubSUbMenu_" + sIndex} style={{ textDecoration: 'none', display: 'flex', }} target="_blank" href={sItem.url} >
                              <p style={{ fontSize: '16px', fontWeight: '300', marginBottom: '15px', color: '#000' }} >{sItem.name}</p>
                            </a>
                          } else {
                            return <a key={"SubSUbMenu_" + sIndex} style={{ textDecoration: 'none', display: 'flex', }}
                              // href={subMenuSubDomain + "#" + selectedSubMenuData.hashValue + "#" + sItem.hashValue}
                              onClick={() => {
                                window.location.href = subMenuSubDomain + "#" + selectedSubMenuData.hashValue + "#" + sItem.hashValue;
                                window.location.reload();
                              }}
                            >
                              <p style={{ fontSize: '16px', fontWeight: '300', marginBottom: '15px', color: '#000' }} >{sItem.name}</p>
                            </a>
                          }
                        })}
                      </div>
                    </>
                  } */}
              })}
            </div>
          </div>}
          <div className="w-layout-grid af-class-menu-grid" style={{ gridRowGap: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 0 }} >
            <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25d1c-23f25d12" className="af-class-main-menu-links">
              <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d31" href="#" className="af-class-menu-link w-inline-block" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(false);
                }
              }} >
                <div>Home</div>
                <div className="af-class-menu-link-underline" />
              </a>

              <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d1d" href="#" className="af-class-menu-link w-inline-block" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(false);
                }
              }} >
                <div>Who We Are</div>
                <div className="af-class-menu-link-underline" />
              </a>
              <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d21" href="#" className="af-class-menu-link w-inline-block" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(false);
                }
              }} >
                <div>Our Businesses</div>
                <div className="af-class-menu-link-underline" />
              </a>
              <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d25" href="#" className="af-class-menu-link w-inline-block" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(false);
                }
              }} >
                <div style={{ lineHeight: 1.4 }} >Sustainability</div>
                <div className="af-class-menu-link-underline" />
                {/* <div style={{ width: '67%' }} >
                  <div style={{ lineHeight: 1.4 }} >&amp; Sustainability</div>
                  <div className="af-class-menu-link-underline" />
                </div> */}
              </a>
              {/* <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d29" href="#" className="af-class-menu-link w-inline-block" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(false);
                }
              }} >
                <div>People &amp; Stories</div>
                <div className="af-class-menu-link-underline" />
              </a> */}
              <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d2d" href="#" className="af-class-menu-link w-inline-block" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(false);
                }
              }} >
                <div>Life at Dr. Reddy's</div>
                <div className="af-class-menu-link-underline" />
              </a>
              {/* <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d31" href="#" className="af-class-menu-link af-class-no-bottom-margin w-inline-block">
              <div>Life at DRL</div>
              <div className="af-class-menu-link-underline" />
            </a> */}
            </div>
            <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25d35-23f25d12" style={{ padding: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '0', paddingTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '40px' }} className="af-class-middle-menu-container">
            <div className="af-class-mid-menu-block af-class-_6"><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <a href="/" className="af-class-sub-menu-heading">HOME</a>
                <a href="/#about-us"  onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>About Us</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/#sustainability"  onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Building A Sustainable Future</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/#we-are-drreddys"  onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>#WeAreDrReddys</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/#we-are-in-the-news"  onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Latest News</div>
                  <div className="af-class-menu-link-underline" />
                </a>
              </div>
              <div className="af-class-mid-menu-block af-class-_1" ><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <a href="who-we-are" className="af-class-sub-menu-heading">
                  <div>Who We Are</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#purpose" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Purpose</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#promises" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Promises</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#principles" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Principles</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#values" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Values</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#leadership" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Leadership</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#our-founder" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our Founder</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/who-we-are#Journey-so-far" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our Journey</div>
                  <div className="af-class-menu-link-underline" />
                </a>
              </div>
              <div className="af-class-mid-menu-block af-class-_2"><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <a href="business-and-capabilities" className="af-class-sub-menu-heading">
                  <div>Our Businesses</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-and-capabilities#our-businesses" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div> Our Business</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-and-capabilities#our-capabilities" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our Capabilities</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-and-capabilities#stories" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Stories</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-and-capabilities#jobs" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Jobs</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-and-capabilities#contact-us" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Contact Us</div>
                  <div className="af-class-menu-link-underline" />
                </a>
              </div>
              <div className="af-class-mid-menu-block af-class-_3"><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <a href="business-responsibility-and-sustainability" className="af-class-sub-menu-heading w-inline-block">
                  {/* <div>Business Responsibility <br />&amp; Sustainability</div>
                  <div className="af-class-menu-link-underline" /> */}
                  <div style={{ lineHeight: 1.4 }} >Sustainability</div>
                  <div className="af-class-menu-link-underline" />
                  {/* <div style={{ width: '70%' }} >
                    <div style={{ lineHeight: 1.4 }} >&amp; Sustainability</div>
                    <div className="af-class-menu-link-underline" />
                  </div> */}
                </a>
                <a href="/business-responsibility-and-sustainability#milestones" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Milestones</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-responsibility-and-sustainability#esg-goals" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our ESG Goals</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-responsibility-and-sustainability#disclosures" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Reports and Disclosures</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-responsibility-and-sustainability#community-initiatives" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Community Initiatives</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-responsibility-and-sustainability#volunteering" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Employee Volunteering</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                {/* <a href="/business-responsibility-and-sustainability#foundation-for-health-education" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Dr. Reddy's Foundation For Health Education</div>
                  <div className="af-class-menu-link-underline" />
                  <div style={{ lineHeight: 1.4 }} >Dr. Reddy's Foundation</div>
                  <div className="af-class-menu-link-underline" />
                  <div style={{ width: '91%' }} >
                    <div style={{ lineHeight: 1.4 }} >For Health Education</div>
                    <div className="af-class-menu-link-underline" />
                  </div>
                </a>
                <a href="/business-responsibility-and-sustainability#our-affiliates"
                  onClick={() => {
                    document.getElementsByClassName("af-class-menu-button")[0].click();
                  }}
                  className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our Affiliates</div>
                  <div className="af-class-menu-link-underline" />
                </a> */}
              </div>
              <div className="af-class-mid-menu-block af-class-_4"><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <span className="af-class-sub-menu-heading w-inline-block">
                  <div>People &amp; Stories</div>
                  <div className="af-class-menu-link-underline" />
                </span>
                <a href="/PeopleStories#patient-stories" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Patient Stories</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/PeopleStories#patient-programmes" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Patient Programmes</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/PeopleStories#hcp-stories" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>HCP Stories</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/PeopleStories#partner-stories" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Partner Stories</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/PeopleStories#be-our-partner" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Be Our Partner</div>
                  <div className="af-class-menu-link-underline" />
                </a>
              </div>
              {/* Extra */}
              {/* <div className="af-class-mid-menu-block af-class-_6"><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <a href="#" className="af-class-sub-menu-heading">Business Something</a>
                <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our culture</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>ASPIRE framework</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our People</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Capability Building Programmes</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Work With Us</div>
                  <div className="af-class-menu-link-underline" />
                </a>
              </div> */}
              {/* Extra Ends Here .....*/}
              <div className="af-class-mid-menu-block af-class-_5"><img src={PrimaryBackFullIcon} loading="lazy" alt className="af-class-sub-menu-back" onClick={() => {
                if (isTab) {
                  setSecondMenuBool(true)
                }
              }} />
                <a href="LifeAtDrReddys" className="af-class-sub-menu-heading w-inline-block">
                  <div>Life At Dr. Reddy's</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/LifeAtDrReddys#our-culture" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our culture</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/LifeAtDrReddys#aspire-framework" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>ASPIRE framework</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/LifeAtDrReddys#our-people" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Our People</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/LifeAtDrReddys#awards" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Awards</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/LifeAtDrReddys#employee-enrichment-programmes" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Capability Building Programmes</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/LifeAtDrReddys#work-with-us" onClick={() => {
                  document.getElementsByClassName("af-class-menu-button")[0].click();
                }} className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                  <div>Work With Us</div>
                  <div className="af-class-menu-link-underline" />
                </a>
              </div>
            </div>
            <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25dac-23f25d12" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dac" className="af-class-side-menu">
              {secondMenuBool && <div data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dad" className="af-class-main-menu-links">
                <a href="/investor" className="af-class-menu-link af-class-small w-inline-block" onClick={(ev) => {
                  if (window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    ev.preventDefault();
                    setSubMenuBool(true);
                    setSubMenuData(InvestorsTabData);
                    setSubMenuSubDomain('/investor');
                  }
                }}>
                  <div>Investors</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/drreddys-media" className="af-class-menu-link af-class-small w-inline-block" onClick={(ev) => {
                  if (window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    ev.preventDefault();
                    setSubMenuBool(true);
                    setSubMenuData(MediaTabData);
                    setSubMenuSubDomain('/drreddys-media');
                  }
                }} >
                  <div>Media</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/business-partner" className="af-class-menu-link af-class-small w-inline-block" onClick={(ev) => {
                  if (window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    ev.preventDefault();
                    setSubMenuBool(true);
                    setSubMenuData(BusinessPartnerTabData);
                    setSubMenuSubDomain('/business-partner');
                  }
                }} >
                  <div>Business Partners</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/job-seeker" className="af-class-menu-link af-class-small w-inline-block">
                  <div>Job Seekers</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                <a href="/contact-us" className="af-class-menu-link af-class-small af-class-no-bottom-margin w-inline-block" onClick={(ev) => {
                  if (window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    ev.preventDefault();
                    setSubMenuBool(true);
                    setSubMenuData(ContactUsTabData);
                    setSubMenuSubDomain('/contact-us');
                  }
                }} >
                  <div>Contact Us</div>
                  <div className="af-class-menu-link-underline" />
                </a>
                {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <a style={{ marginTop: '20px' }} href="#" className="af-class-country-link af-class-india w-inline-block" onClick={(ev) => {
                  ev.preventDefault();
                  setSubMenuBool(true);
                  setSubMenuData(CountrySitesTabData);
                  setSubMenuSubDomain('/country-sites');
                }} >
                  <img src="/images/Icon-feather-globe-primary.svg" loading="lazy" alt className="af-class-globe-icon" />
                  <div style={{ color: '#5225B5' }} >Country Sites</div>
                </a>}
              </div>}
              {!isTab && <div className="af-class-countries-wrapper">
                <a id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25dc3-23f25d12" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dc3" href="#" className="af-class-country-link af-class-india w-inline-block"><img src="/images/Icon-feather-globe-primary.svg" loading="lazy" alt className="af-class-globe-icon" />
                  <div style={{ color: '#5225B5' }} >Country Sites</div>
                </a>
                <div className="af-class-country-links-block">
                  <a target="_blank" href={config.countrySites.australia}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Australia", url: config.countrySites.australia })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>Australia</div>
                  </a>
                  <a target="_blank" href={config.countrySites.austria}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Austria", url: config.countrySites.austria })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>Austria</div>
                  </a>
                  <a target="_blank" href={config.countrySites.brazil}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Brazil", url: config.countrySites.brazil })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>Brazil</div>
                  </a>
                  <a target="_blank" href={config.countrySites.cananda}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Canada", url: config.countrySites.cananda })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>Canada</div>
                  </a>
                  <a target="_blank" href={config.countrySites.chile}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Chile", url: config.countrySites.chile })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>chile</div>
                  </a>
                  <a target="_blank" href={config.countrySites.china}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "China", url: config.countrySites.china })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>china</div>
                  </a>
                  <a target="_blank" href={config.countrySites.colombia}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Colombia", url: config.countrySites.colombia })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>colombia</div>
                  </a>
                  <a target="_blank" href={config.countrySites.france}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "France", url: config.countrySites.france })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>france</div>
                  </a>
                  <a target="_blank" href={config.countrySites.germany}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Germany", url: config.countrySites.germany })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>germany</div>
                  </a>
                  <a target="_blank" href={config.countrySites.india}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "India", url: config.countrySites.india })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>India</div>
                  </a>
                </div>
                <div className="af-class-country-links-block">
                  <a target="_blank" href={config.countrySites.italy}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Italy", url: config.countrySites.italy })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>Italy</div>
                  </a>
                  {/* <a target="_blank" href={config.countrySites.netherlands}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Netherlands", url: config.countrySites.netherlands })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>netherlands</div>
                  </a> */}
                  <a target="_blank" href={config.countrySites.new_zealand}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "New Zealand", url: config.countrySites.new_zealand })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>new zealand</div>
                  </a>
                  <a target="_blank" href={config.countrySites.romania}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Romania", url: config.countrySites.romania })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>romania</div>
                  </a>
                  <a target="_blank" href={config.countrySites.russia}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Russia", url: config.countrySites.russia })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>russia</div>
                  </a>
                  <a target="_blank" href={config.countrySites.southAfrica}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "South Africa", url: config.countrySites.southAfrica })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>south africa</div>
                  </a>
                  <a target="_blank" href={config.countrySites.spain}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Spain", url: config.countrySites.spain })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>spain</div>
                  </a>
                  <a target="_blank" href={config.countrySites.ukraine}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "Ukraine", url: config.countrySites.ukraine })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>ukraine</div>
                  </a>
                  <a target="_blank" href={config.countrySites.uk}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "United Kingdom", url: config.countrySites.uk })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>united kingdom</div>
                  </a>
                  <a target="_blank" href={config.countrySites.us}
                    onClick={(ev) => {
                      ev.preventDefault();
                      document.getElementsByClassName("af-class-menu-button")[0].click();
                      setAlertBool(true);
                      setCountrySiteDesc({ name: "United States", url: config.countrySites.us })
                    }}
                    className="af-class-country-link w-inline-block">
                    <div>united states</div>
                  </a>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </nav>
  </>
}

export default MenuSection;
