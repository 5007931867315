/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import { HR } from "../pages/common/horizontalLine";
import AwardsSlider from '../Common/MobileSlider/AwardsSlider';



const isTabsEnable = true

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;

const MainTabContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;


const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  margin-bottom: 35px !important;
`;

const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  margin-right: 4%;
`;

const TabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#5225B5' : '#909090')};
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const MobileTabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: #000000;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`;


const HambergerButton = styled.a`
  /* using following code for increasing clickable area */
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 2em;
  margin: -2em;
  cursor: pointer;
`;

const HambergerIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 99999999999999;
  width: 100%;
  display: block;
  background-color: #ededed;
  top: 0;
  left: 0;
  padding-top: 20%;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  margin-top: 10px;
  &::before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(-45deg);
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TabMenuItem = styled.a`
  cursor: pointer;
  font-size: 16px;
  color:  ${({ selected }) => (selected ? "#5225B5" : "#000")};;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  padding-bottom: 5px;
  text-decoration:none !important;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

// const isTabsEnable = useMediaQuery({ query: "(min-device-width: 1000px)" });

const TabData = [
  {
    id: 1, name: "Being committed to environmental stewardship", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 2, name: "Making our products accessible and affordable to patients", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 3, name: "Contributing to a fairer and more socially inclusive world", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 4, name: "Enhancing trust with our stakeholders", bgColor: '#fff', hashValue: '', subMenuTabData: []
  }
]


const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("/webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

import MenuSection from '../Common/MenuSection'
// import VideoSection from '../Common/BusinessResponsibilityAndSustainability/VideoSection'
import ImageSlider from '../Common/BusinessResponsibilityAndSustainability/ImageSlider'
import ImageSliderMobile from '../Common/BusinessResponsibilityAndSustainability/ImageSliderMobile'
// import ImageSliderGsap from '../Common/BusinessResponsibilityAndSustainability/ImageSliderGsap'
import Health from '../Common/BusinessResponsibilityAndSustainability/Health'
import HealthMobile from '../Common/BusinessResponsibilityAndSustainability/HealthMobile'
import FooterSection from '../Common/FooterSection'

import HeroBanner from '../Common/BusinessResponsibilityAndSustainability/HeroBanner';

import axios from 'axios';
import config from '../config';
import ImageTextSlider from '../Common/BusinessResponsibilityAndSustainability/ImageTextSlider'
import EnvironmentMobile from '../Common/BusinessResponsibilityAndSustainability/EnvironmentMobile'
import EducationSlider from '../Common/MobileSlider/EducationSlider';
import LivelihoodSlider from '../Common/MobileSlider/LivelihoodSlider';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

import Img1 from '../assets/CHIP.jpg';
import Img2 from '../assets/healthMobImg2.jpg';

import { isTab, mediaQueryRangeTab } from '../Utils/Utils';

import styled from 'styled-components';

const RenderText = styled.p`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;
   fontSize: 14px;
   color:#000;
   fontWeight: 200;
  `;

class BusinessResponsibilityAndSustainabilityView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      articleData: {},
      quickLinksData: [],
      hideMenu: false,
      renderPageBool: false,
      leftDisabled2: true,
      rightDisabled2: false,
      leftDisabled: true,
      rightDisabled: false,
      cookieBool: true,
      readMore: '',
      selectedTab: TabData[0],
      selectedSubTab: 1,
      tabData: TabData,
      currentPage: 0,
    }

    this.getTabContent = this.getTabContent.bind(this);
    this.getTabImage = this.getTabImage.bind(this);
    this.handleLeftClick = this.handleLeftClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleLeftArrowClick = this.handleLeftArrowClick.bind(this);
    this.handleRightArrowClick = this.handleRightArrowClick.bind(this);

  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ImpactAndSustainabilityController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = BusinessResponsibilityAndSustainabilityView

        return Controller
      }

      throw e
    }
  }

  loadScript() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60b0a96543bf9bef0dfa92f5'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true }, () => {
          // if (!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
          window.scrollTo({ top: window.scrollY - 1, left: 0, });
          window.scrollTo({ top: window.scrollY + 1, left: 0, });
          // }
        })
      }

      return active.isAsync ? next : loading
    }))
  }

  handleLeftClick() {
    console.log('came here...');

    var newTab = this.state.selectedTab.id - 1;
    var right = this.state.rightDisabled, left = this.state.leftDisabled;
    if (newTab < 1) { newTab = 1 };
    this.setState({ selectedTab: this.state.tabData[newTab - 1] });

    if (newTab == 1) {
      right = false;
      left = true;
    }

    if (newTab == 2 || newTab == 3) {
      right = false;
      left = false;
    }

    this.setState({ leftDisabled: left, rightDisabled: right });
  };

  handleLeftArrowClick() {
    var pageCount = this.state.currentPage - 1;
    this.setState({ currentPage: pageCount });
  }

  handleRightArrowClick() {
    var pageCount = this.state.currentPage + 1;
    this.setState({ currentPage: pageCount });
  }

  handleRightClick() {
    console.log('came to right click...');
    var newTab = this.state.selectedTab.id + 1;
    var right = this.state.rightDisabled, left = this.state.leftDisabled;

    if (newTab > 4) { newTab = 4 };
    this.setState({ selectedTab: this.state.tabData[newTab - 1] });

    if (newTab == 4) {
      right = true;
      left = false;
    }

    if (newTab == 2 || newTab == 3) {
      right = false;
      left = false;
    }

    this.setState({ leftDisabled: left, rightDisabled: right });
  };

  UNSAFE_componentWillMount() {

    var link = document.createElement('meta');
    link.setAttribute('name', 'keywords');
    link.content = "pharmaceutical company, affordable medicine, pharmaceutical services, pharmaceutical industry, drugs manufacturer, dr reddys";
    document.getElementsByTagName('head')[0].appendChild(link);

    var tempObj = {};
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/impactarticles?sort=created&&include=field_thumbnail,field_coverimage", })
      .then((res) => {
        const { data, included } = res.data;
        var section1 = [];
        var section2 = [];
        var section3 = [];
        var section4 = [];
        var imgPathsObj = {};
        for (var imgObj of included) {
          imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
        }
        for (var item of data) {
          tempObj = {
            id: item.id,
            contentId: '5',
            title: item.attributes.title,
            coverImage: item.relationships.field_coverimage.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_coverimage.data.id] : "",
            thumbnail: item.relationships.field_thumbnail.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_thumbnail.data.id] : "",
            description: item.attributes.field_description ? item.attributes.field_description.processed : "",
            content: item.attributes.field_content ? item.attributes.field_content.processed : "",
            externalLink: item.attributes.field_external_link ? item.attributes.field_external_link.uri : "",
          }
          if (item.attributes.field_impactcategory === "impactandsustainability-Section-1") {
            section1.push(tempObj);
          } else if (item.attributes.field_impactcategory === "impactandsustainability-Section-2") {
            section2.push(tempObj);
          } else if (item.attributes.field_impactcategory === "impactandsustainability-Section-3") {
            section3.push(tempObj);
          } else if (item.attributes.field_impactcategory === "impactandsustainability-Section-4") {
            section4.push(tempObj);
          }
        }
        console.log({
          section1, section2, section3, section4
        }, "-=-=-=  ")
        this.setState({
          articleData: {
            section1, section2, section3, section4
          }
        }, () => {
          this.loadScript();
        })
      }).catch((Err) => {
        this.loadScript();
        console.log(Err, "-=-=-=  ERR")
      })

    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/sustainability_quick_links?include=field_file&sort=field_sequence_order_", })
      .then((res) => {
        const { data, included } = res.data;
        var imgPathsObj = {};
        var tempArrData = [];
        for (var imgObj of included) {
          imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
        }
        for (var item of data) {
          tempArrData.push({
            id: item.id,
            title: item.attributes.title,
            file: item.relationships.field_file.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_file.data.id] : "",
          });
        }
        this.setState({
          quickLinksData: [...tempArrData]
        })
      }).catch((Err) => {
        console.log(Err, "-=-=-=  ERR")
      })
  }

  getTabContent(selectedTab) {

    switch (selectedTab.id) {
      case 1: return (
        <>
          <p ref={(el) => {
            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
              if (isTab) {
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
              } else {
                el.style.setProperty('line-height', '24px', 'important');
                el.style.setProperty('font-size', '14px', 'important');
              }
            }
          }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
            Reducing carbon emissions
          </p>
          <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>100% renewable power by 2030</li>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Carbon neutrality in our direct operations (Scope 1 and Scope 2 emissions) by 2030</li>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>12.5% reduction in indirect carbon emissions across our supply chain (Scope 3 emissions) by 2030</li>
          </ul>
          <p ref={(el) => {
            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
              if (isTab) {
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
              } else {
                el.style.setProperty('line-height', '24px', 'important');
                el.style.setProperty('font-size', '14px', 'important');
              }
            }
          }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
            Water Positivity
          </p>
          <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
            <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Be water positive by 2025</li>
          </ul>
        </>
      )

      case 2: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Access
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Serve 1.5 billion patients by 2030</li>
        </ul>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Affordability
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>25% of our new-launches to be first-to-market by 2027</li>
        </ul>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Innovation
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>3 innovative solutions to improve the standard of treatment every year from 2027</li>
        </ul>
      </>)
      case 3: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Equity, Diversity and Inclusion
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>At least 35% women in senior leadership by 2030</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Gender parity by 2035 </li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Include at least 3% persons with disabilities (PwD) in our workforce by 2030</li>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>100% living wages for our on-premise extended workforce by 2025</li>
        </ul>
      </>)

      case 4: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Compliance, Ethics, Corporate Governance
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Meet the highest standards on compliance and ethics backed by robust corporate governance </li>
        </ul>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          ESG disclosures
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>Progressively enhance disclosure on our ESG progress to reach top quartile by 2025</li>
        </ul>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Strategic suppliers 
        </p>
        <ul style={{ margin: 0, padding: 0, lineHeight: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '14px' : '16px', fontWeight: 300 }}>
          <li style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '3px' : '7px' }}>100% strategic suppliers to be compliant with our ESG framework by 2030</li>
        </ul>
      </>)
      default: return (<div></div>)
    }
  }

  getTabImage(selectedTab) {

    switch (selectedTab.id) {
      case 1: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/esg_goals_img1.png" : "/images/esg_goals_img1.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 2: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/shashank-thapa-MkPINODL-Tw-unsplash.png" : "/images/shashank-thapa-MkPINODL-Tw-unsplash.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 3: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/_DSC2209-Edit.png" : "/images/_DSC2209-Edit.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 4: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/pexels-karolina-grabowska-7875996.png" : "/images/pexels-karolina-grabowska-7875996.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      default: return (<div></div>)
    }
  };

  render() {
    const proxies = BusinessResponsibilityAndSustainabilityView.Controller !== BusinessResponsibilityAndSustainabilityView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"3a7c9c05-63f0-b2cc-7810-1ad5891f34b9\"] {display:none;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"3a7c9c05-63f0-b2cc-7810-1ad5891f34b9\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"3a7c9c05-63f0-b2cc-7810-1ad5891f34b9\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"3a7c9c05-63f0-b2cc-7810-1ad5891f34b9\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar,
            		.delivering-health-wrapper::-webkit-scrollbar,
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
            .af-class-article-text-thumbnail-wrapper:hover h3 {
              text-decoration: underline;
            }

            .af-view .af-class-time-line-marker:before {
              height: 240px;
              top: -240px;
            }

        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999999999999999 }} ></span>}
        <span id="back-to-top" className="af-view">
          <div>

            {!this.state.hideMenu && <MenuSection subMenuClick={() => {
              this.setState({ renderPageBool: false })
            }} />}


            <HeroBanner />

            <section id="milestones" data-w-id="8b095fb5-ee28-3b2f-8989-b5a8f5ac8121" className="af-class-section af-class-journey-section"
              style={{
                height: '110vh',
                minHeight: '110vh',
                backgroundColor: "#EDEDED",
                paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '240px',
                display: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'flex',
                alignItems: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'center',
              }}>
              <div className="af-class-container w-container">
                {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div style={{
                  borderBottom: '1px solid #ff5046', marginBottom: '20px',
                  paddingBottom: '20px',
                  textUnderlineOffset: '10px',
                }} >
                  <h3 className="af-class-h3-heading" style={{ fontSize: '16px', fontWeight: 'bold', textTransform: 'none', margin: 0, marginBottom: '2 0px' }} >20 YEARS OF LEADING THE INDUSTRY IN SUSTAINABILITY</h3>
                </div>}
                <div className="af-class-timeline-wrapper">
                  <div className="af-class-time-line-marker">
                    <div data-w-id="205462a9-d649-00ae-41e3-d53ce1b40472" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-timeline-dot" />
                  </div>
                  <div className="af-class-years-block" style={{ position: "sticky" }} >

                    <div data-w-id="fc3812a5-12f6-a020-8989-2b46ddd2e2bc" className="af-class-year-text">
                      <div data-w-id="99d70436-c84d-3520-e143-f088eb5a9211" className="af-class-timeline-dot af-class-mobile" />
                      <div data-w-id="562362d4-bfb6-b4cf-085d-d5a368f5c814" style={{ fontWeight: 'bold' }} >2004</div>
                      <div data-w-id="aa62f39b-59a8-be68-5678-77b179e6571e" style={{ width: '0%' }} className="af-class-year-underline" />
                    </div>

                    <div data-w-id="0ccdee18-fa19-a667-8989-32cded283602" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2007</div>
                      <div data-w-id="7f63f200-4735-055f-ef29-ec1de8b73d69" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>

                    <div data-w-id="8684a292-2004-4544-8989-730b2f4dcaf8" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2010</div>
                      <div data-w-id="67293bb8-4d73-a363-87cf-9350b4573968" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>

                    <div data-w-id="319689f1-0471-7ef1-8989-0d62dbab72b9" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2013</div>
                      <div data-w-id="3925ae23-4a5d-89ae-c7f0-b8369e164a4e" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                    <div data-w-id="d5c6889c-6c45-b7df-8989-d9c590d72ece" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2016</div>
                      <div data-w-id="8408ad78-cf8c-a7a3-ec79-852199b2e9f1" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                    <div data-w-id="feb7428a-c74e-c8f9-8989-9fef637d7ff3" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2020</div>
                      <div data-w-id="ab8118c3-5d0c-f14a-7820-4f33a90b67b6" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                    <div data-w-id="0976f596-837e-b84d-8989-2d938ba4c56c" className="af-class-year-text">
                      <div style={{ fontWeight: 'bold' }} >2022</div>
                      <div data-w-id="6b1b712b-9d3d-b2ce-aae3-2a17d1f1d5b3" style={{ width: '0%' }} className="af-class-year-underline" />
                      <div className="af-class-timeline-dot af-class-mobile" />
                    </div>
                  </div>

                  <div className="af-class-year-numerical-wrapper" style={{ position: "sticky" }}>
                    <div className="af-class-year-digit">
                      <div data-w-id="0b4d8531-0a89-fecc-d301-c36927da109e" className="af-class-year-numerical-1" style={{ textAlign: 'right' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                    <div className="af-class-year-digit">
                      <div data-w-id="b4bf86e9-b4b9-8a9b-b938-62dcc59edabe" className="af-class-year-numerical-2" style={{ textAlign: 'left' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                    <div className="af-class-year-digit af-class-bottom">
                      <div data-w-id="85d68a56-476f-7846-384c-e8278098bfbc" className="af-class-year-numerical-3" style={{ textAlign: 'right' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                    <div className="af-class-year-digit">
                      <div data-w-id="d93819cf-ea4b-1cfb-63c1-d780a0a254ca" className="af-class-year-numerical-4" style={{ textAlign: 'left' }}>1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />0</div>
                    </div>
                  </div>

                  <div data-w-id="4faef0df-bc8a-51fc-6392-217069a7b600" className="af-class-yearly-content-wrapper">
                    {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading" style={{ marginTop: '20px', marginBottom: '10px' }}>20 YEARS OF LEADING THE INDUSTRY IN SUSTAINABILITY</h3>}

                    <div className="af-class-year-content-block af-class-_1">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2004</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}>First Sustainability Report</div>

                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              We began voluntary disclosures on social and environmental aspects.
                            </p>
                            <br />
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}>First Sustainability Report</div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300' }} >
                              We began voluntary disclosures on social and environmental aspects.
                            </p>
                          </>
                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_2">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2007</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}>Creation of sustainability statement</div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Covering product responsibility, transparency,
                              operational excellence, human dignity, equal opportunity, and community development
                            </p>
                            <br />
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}>Creation of sustainability statement</div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300' }} >
                              Covering product responsibility, transparency, operational excellence, human dignity, equal opportunity, and community development
                            </p>
                          </>

                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_3">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2010</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}>Conducted materiality assessment</div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Environmental commitment statement (ECS) with six Environment, Social Impact and Governance (ESG) goals for 2020
                            </p>
                            <br />
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}>Conducted materiality assessment</div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300' }} >
                              Environmental commitment statement (ECS) with six Environment, Social Impact and Governance (ESG) goals for 2020
                            </p>
                          </>
                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_4">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2013</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}>‘Sustainability by Design’ <br />approach in operations</div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              We introduced the approach to integrate sustainability into our operations encompassing people, safety, quality, availability, environment, engineering excellence, productivity, continuous improvement, and community
                            </p>
                            <br />
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}>‘Sustainability by Design’ <br />approach in operations</div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ fontSize: '18px', fontWeight: '300' }} >
                              We introduced the approach to integrate sustainability into our operations encompassing people, safety, quality, availability, environment, engineering excellence, productivity, continuous improvement, and community
                            </p>
                          </>
                      }
                    </div>


                    <div className="af-class-year-content-block af-class-_5">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2016</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}></div>
                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Committed to delivering good health consistently and going beyond compliance for a sustainable future
                            </p>
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}></div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ fontSize: '18px', fontWeight: '300' }} >
                              Committed to delivering good health consistently and going beyond compliance for a sustainable future
                            </p>
                          </>
                      }
                    </div>


                    <div className="af-class-year-content-block af-class-_6">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2020</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}>2010 ECS wrap-up</div>

                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              We achieved three goals completely and three goals partially. Conducted materiality assessment and integrated ESG goals with purpose and business strategy'
                            </p>
                            <br />
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}>2010 ECS wrap-up</div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300' }} >
                              We achieved three goals completely and three goals partially. Conducted materiality assessment and integrated ESG goals with purpose and business strategy'
                            </p>
                          </>
                      }
                    </div>

                    <div className="af-class-year-content-block af-class-_7">
                      {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <h3 className="af-class-h3-heading">2022</h3>}
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <>
                            <div className="af-class-timeline-heading" style={{ fontSize: '24px', lineHeight: '30px', marginTop: '20px' }}>Our current ESG Goals</div>

                            <p ref={(el) => {
                              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px', }} >
                              Made sustainability core to our purpose and strategy, strengthened our sustainability commitment for the next decade
                            </p>
                            <br />
                          </>
                          :
                          <>
                            <div className="af-class-timeline-heading" style={{ maxWidth: '520px', marginTop: '20px' }}>Our current ESG Goals</div>
                            <p ref={(el) => {
                              if (el) { el.style.setProperty('line-height', '30px', 'important'); }
                            }} style={{ maxWidth: '520px', fontSize: '18px', fontWeight: '300' }} >
                              Made sustainability core to our purpose and strategy, strengthened our sustainability commitment for the next decade
                            </p>
                          </>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </section>


            <div id="esg-goals" className="af-class-section">
              <div className="af-class-container" data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7">

                <h2 ref={(el) => {
                  if (el && isTab) {
                    el.style.setProperty('font-size', '36px', 'important')
                    el.style.setProperty('margin-bottom', '20px', 'important')
                  }
                }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin af-class-center"
                  style={{
                    marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '-10px' : '-10px',
                    marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px',
                    fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px', textAlign: 'center'
                  }} >Our current ESG goals</h2>


                {!window.matchMedia('(max-width: 820px)').matches && <MainContainer isTabsEnable={isTabsEnable}>

                  <TabsSection>
                    {isTabsEnable &&
                      this.state.tabData.map((item, index) => {
                        return (
                          <TabItem
                            href={"#" + item.hashValue}
                            key={"Rendering_Tab_contactUs_" + item.id + item.name + index}
                            onClick={() => {
                              this.setState({ selectedTab: item, selectedSubTab: 0 });
                            }}
                            selected={this.state.selectedTab.id === item.id}
                          >
                            {item.name}
                          </TabItem>
                        );
                      })}
                    {!isTabsEnable && (
                      <HambergerButton
                        onClick={() => {
                          this.setState({ openMenuBool: !this.state.openMenuBool });
                        }}
                      >
                        <HambergerIcon />
                      </HambergerButton>
                    )}
                  </TabsSection>
                </MainContainer>}

                {!window.matchMedia('(max-width: 820px)').matches && <HR />}

                {window.matchMedia('(max-width: 820px)').matches &&
                  <MainContainer style={{ flexDirection: 'column', marginTop: 0, marginBottom: '10px' }}>
                    <TabsSection style={{ marginLeft: 0 }}>
                      {isTabsEnable &&
                        <><MobileTabItem
                          href={"#" + this.state.selectedTab.hashValue}
                          key={"Rendering_Tab_contactUs_" + this.state.selectedTab.id + this.state.selectedTab.name}
                          selected={this.state.selectedTab.id}
                        >
                          {this.state.selectedTab.name}
                        </MobileTabItem>
                          <a
                            onClick={() => {
                              var selectedValue = this.state.selectedTab.id + 1;
                              if (selectedValue > 4) selectedValue = 1;

                              this.setState({ selectedTab: this.state.tabData[selectedValue - 1], selectedSubTab: 0 });
                            }}
                          >
                            <div style={{ width: '10px', height: '16px', marginTop: '-10px' }} >
                              <img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" /></div>
                          </a>
                        </>
                      }
                    </TabsSection>
                    <div style={{ marginBottom: '20px', display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '20px' }} >
                      {
                        isTab
                          ?
                          <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((this.state.selectedTab.id - 1 - 1) * (100 / (4 - 1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (4 - 1)) + "%" }} />
                          :
                          <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((this.state.selectedTab.id - 1) * (100 / 4)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / 4) + "%" }} />
                      }
                    </div>

                  </MainContainer>
                }

                {/* {!window.matchMedia('(max-width: 820px)').matches && <div style={{ width: '100%' }} >
                    <ul style={{ display: 'flex', flexWrap: 'wrap', lineHeight: 1.5, paddingLeft: '25px' }} >

                    </ul>
                  </div>} */}

                {/* {!window.matchMedia('(max-width: 820px)').matches && <HR />} */}

                {/* {
                    window.matchMedia('(max-width: 820px)').matches && <div className="disable-scroll-indicator" style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', marginBottom: '50px', width: 'calc(calc(100% + 20px + 20px))', marginLeft: '-20px', marginRight: '-20px', }} >
                      {
                        this.state.tabData.map((item, index) => {
                          return (
                            item.hasOwnProperty("url") && item.url.length !== 0
                              ?
                              <a key={item.id} id={"MainTabs:" + item.id} style={{ textDecoration: this.state.selectedTab?.name === item.name ? 'underline' : 'none', textUnderlineOffset: '4px', color: '#000', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', marginRight: '15px', marginRight: this.state.tabData.length - 1 === index ? '20px' : '15px', marginLeft: index === 0 && '20px' }}
                                target="_blank" href={item.url}
                              >
                                <p style={{ fontSize: '16px', fontWeight: 'bold', color: this.state.selectedTab?.name === item.name ? '#5225B5' : '#909090', margin: '0px', whiteSpace: 'nowrap' }} >{item.name}</p>
                              </a>
                              :
                              <a key={item.id} id={"MainTabs:" + item.id} style={{ textDecoration: this.state.selectedTab?.name === item.name ? 'underline' : 'none', textUnderlineOffset: '4px', color: '#000', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', marginRight: '15px', marginRight: this.state.tabData.length - 1 === index ? '20px' : '15px', marginLeft: index === 0 && '20px' }}
                                onClick={() => {
                                  this.setState({ selectedTab: item })
                                }}
                              >
                                <p style={{ fontSize: '16px', fontWeight: 'bold', color: this.state.selectedTab?.name === item.name ? '#5225B5' : '#909090', margin: '0px', whiteSpace: 'nowrap' }} >{item.name}</p>
                              </a>
                          )
                        })
                      }
                    </div>
                  } */}

                {/* { window.matchMedia('(max-width: 820px)').matches && this.state.selectedTab?.subMenuTabData && this.state.selectedTab.subMenuTabData.map((item, index) => {
                      return (
                        item.hasOwnProperty("url") && item.url.length !== 0
                          ?
                          <div key={"MenuListView:" + index} >
                            <a style={{ textDecoration: 'none', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} target="_blank" href={item.url} >
                              <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name}</p>
                              <img  loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                            </a>
                            <HR />
                          </div>
                          :
                          <div key={"MenuListView:" + index} >
                            <a style={{ textDecoration: 'none', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} href={"/detail-sub-menu#" + item.hashValue} >
                              <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name}</p>
                              <img  loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                            </a>
                            <HR />
                          </div>
                      )
                    })
                  } */}


                <div className="af-class-delivering-health-wrapper" style={{ alignItems: 'center', display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >

                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&
                    <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{
                      pointerEvents: this.state.leftDisabled ? 'none' : 'auto',
                      position: 'absolute', top: '30%', left: '1%', marginLeft: '-60px', padding: 0
                    }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={() => this.handleLeftClick()}>

                      {this.state.leftDisabled ?
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                        </>
                        :
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                      }
                    </div>}


                  <div className="af-class-scroll-video-block" style={{
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset',
                    marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                    marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                    marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
                  }}>

                    <div style={{
                      margin: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '12px 12px 0px 12px'
                    }}>
                      {this.getTabContent(this.state.selectedTab)}
                      {/* <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block"
                            style={{marginTop: '30px', marginLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '-20px'}}>
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                            <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600'
                              }} >LEARN&nbsp;MORE</div>
                          </a> */}
                    </div>
                  </div>
                  {this.getTabImage(this.state.selectedTab)}


                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{
                    pointerEvents: this.state.rightDisabled ? 'none' : 'auto',
                    position: 'absolute', top: '30%', left: '96%'
                  }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={() => this.handleRightClick()}>

                    {this.state.rightDisabled ?
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                      :
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                    }
                  </div>}
                </div>

              </div>
            </div>

            <div className="af-class-section af-class-z-index-2" data-w-id="ac2b7f71-6c4e-be07-c418-51677fd64972" style={{ height: 'auto', backgroundColor: '#EDEDED' }} >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">Accreditations and awards</h2>
                <AwardsSlider />
                {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                  <div className="af-class-drag-slider-wrapper">
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                      {/* <div className="af-class-mask af-class-_350 w-slider-mask"> */}
                      <div className="af-class-mask w-slider-mask">

                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/EcoVadis_Story.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Gold Medal status by EcoVadis</h3>
                              <div>We have been awarded ‘Gold Medal’ status by EcoVadis, the global sustainability ratings agency, for its score of 72 out of 100 in its scorecard for 2023.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/DJSI_Story.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Dow Jones Sustainability Index</h3>
                              <div>We have become the first Indian pharma company to earn a place in the Standard & Poor Dow Jones Sustainability World Index (DJSI World) for 2023. Alongside our debut in DJSI World, we also retain our place in the Dow Jones Sustainability Index for Emerging Markets (DJSI EM) for the 8th year in a row.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Asia_Pacific_Leader.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Asia-Pacific Climate Leader 2024 by the Financial Times (London)</h3>
                              <div>For the second year in a row, The Financial Times (London) has named us an ‘Asia-Pacific Climate Leader' in its 2024 list. The annual study is compiled by Financial Times and Statista. Asia-Pacific Climate Leaders 2024 is a list of 350 Asia-Pacific companies that have achieved the greatest reduction in their core emissions intensity over the 5-year period between 2017 and 2022.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Bloomberg.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Bloomberg GEI</h3>
                              <div>For the 6th year in a row, we have been included in the Bloomberg LP Gender-Equality Index (GEI) 2023, featuring as the only Indian pharma company. The index measures gender equality across internal company statistics, employee policies, external community support, engagement, and gender-conscious product offerings.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/SustainabilityYearbook.png" loading="lazy" alt className="af-class-drag-slider-item-image" style={{ objectFit: "fill" }} /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Sustainability Yearbook</h3>
                              <div>We have been included in S&P Global's Sustainability Yearbook in the Top 10% category for the first time based on our 2023 Corporate Sustainability Assessment (CSA) score. We are placed 6th globally in pharma in the CSA. We have been a member of the S&P Sustainability Yearbook for four years in a row.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/CDP_Image.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">CDP</h3>
                              <div>Our ESG efforts for 2023 have been recognised by CDP - in supplier engagement, climate change and water security. We have received an 'A' on Supplier Engagement rating, which is in the Leadership band. We are the only Indian pharma company to get an A- rating in Climate Change and Water Security for 2023 disclosures by CDP.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/award4.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Science Based Targets initiative</h3>
                              <div>We joined the Science Based Targets initiative’s (SBTi) business ambition in 2020, becoming the first Indian and the third Asian pharmaceutical company to have set its Science-Based Targets to further minimize environmental impact.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/award5.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Sustainable Company of the Year</h3>
                              <div>Recognised with the prestigious apex award – Sustainable Corporate of the Year award – at the 2021 Frost & Sullivan-TERI Sustainability 4.0 Awards. </div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/award7.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Indian Green Building Council - IGBA</h3>
                              <div>We have been awarded with the Green Champion Award 2022 under the category 'Pioneer in large-scale adoption of green factory buildings in India’. This highly coveted distinction makes us part of the distinguished community spearheading the Green and Net Zero Building Movement in India.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/award8.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">UN Women Empowerment Principles Awards</h3>
                              <div>In October 2021, we were 2nd runner-up in the United Nations Women’s Empowerment Principles (WEPs) Gender-inclusive Workplace category in Asia-Pacific. We recently joined the United Nations Women Empowerment Principles as a signatory. </div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-999b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/ftse.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">FTSE4Good</h3>
                              <div>We remain a constituent of this index series as per the attached certificate (June 2022). Our ESG rating is 3.9/5 (as of September 2022), translating to a percentile rank of 90%. The FTSE4Good Index Series uses the overall Rating from FTSE Russell's ESG Ratings and data model to select companies for inclusion. The ESG Ratings include over 7,200 securities in 47 Developed and Emerging markets.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998c-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/refinitiv.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Refinitiv</h3>
                              <div>We score 88/100, and this makes us top quartile (maps to our ESG goal). We are also ranked 7th out of the 426 pharma companies in their database. As per the company, scores of above the 75 range indicate excellent relative ESG performance and high degree of transparency in reporting material ESG data publicly.</div>
                            </div>
                          </div>
                        </div>

                        {/* <div data-w-id={"ae2c330c-3b91-6fe2-998c-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/jason-goodman-4-iZ147pSAE-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">I-Develop</h3>
                              <div>With I-Develop, HR business partners undergo a year-long training for capability building across functions. They’re helped by external experts to develop expertise in various fields, from data analytics to talent development, career planning and employee experience.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998d-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/docusign-BbSBf5uv50A-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Digital Capability Building Programmes</h3>
                              <div>As a future-forward company, enabling our employees to be digital savvy is one of our priorities. This aligns with our digitisation campaign to build a business that will flourish for decades. Our courses are customised to the needs of different units, from manufacturing to finance and marketing.</div>
                            </div>
                          </div>
                        </div> */}

                      </div>


                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: this.state.currentPage === 0 ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={this.handleLeftArrowClick}>
                        {
                          this.state.currentPage === 0 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }

                      </div>
                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: this.state.currentPage === 2 ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={this.handleRightArrowClick}>
                        {
                          this.state.currentPage === 2 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }
                      </div>


                      {/* <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50a" className="af-class-left-arrow af-class-lower af-class-down-90px w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50e" className="af-class-right-arrow af-class-lower af-class-down-90px w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e510" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div> */}



                      <div className="af-class-slide-nav w-slider-nav w-round" />
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar">
                    <div className="af-class-drag-progress-fill af-class-forty" />
                  </div>
                </>}
              </div>
            </div>



            {/* ESG Statement */}
            {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div id="disclosures" data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-section af-class-z-index-2" >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }}>
                {/* <div  style={{  }} > */}
                <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100%', height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'unset' }}>
                  <div style={{ paddingLeft: '100px' }}>
                    {/* <h2 ref={(el)=>{
                                if(el && isTab){
                                  el.style.setProperty('font-size','36px','important')
                                  el.style.setProperty('margin-bottom','20px','important')
                                }
                              }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
                    <p ref={(el) => {
                      if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                        if (isTab) {
                          el.style.setProperty('font-size', '20px', 'important');
                          el.style.setProperty('line-height', '32px', 'important');
                          el.style.setProperty('max-width', '100%', 'important');
                        } else {
                          el.style.setProperty('line-height', '28px', 'important');
                        }
                      }
                    }} className="af-class-paragraph af-class-_450" style={{ maxWidth: '100%', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >We released our first sustainability report in 2004 and have maintained annual disclosures since then. Our full set of reports are available here.</p>
                    <a href="/investor#reports-and-filing#sustainability-reports" target="_blank" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                      <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                      <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >LEARN&nbsp;MORE</div>
                    </a>
                  </div>
                </div>
                <div ref={(el) => {
                  if (el && isTab) {
                    el.style.setProperty('height', '280px', 'important')
                  }
                }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'auto' : '100%', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100%', marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '20px' }}>
                  <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/sustainability_poster_mobile.png" : "/images/sustainability_poster.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: 'auto', width: '100%', objectFit: 'cover', marginBottom: '20px' } : { height: '100%', width: '100%', objectFit: 'cover' }} />
                </div>
                {/* </div> */}
              </div>
            </div>
            }



            {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div id="disclosures"
              data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-section af-class-z-index-2"
              style={{ minHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100vh', backgroundColor: '#EDEDED' }}>
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <div style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
                  <div className="af-class-scroll-video-block" style={{
                    padding: 0,
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%",
                    maxHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '240px'
                  }}>
                    <div>
                      {/* <h2 ref={(el)=>{
                                if(el && isTab){
                                  el.style.setProperty('font-size','36px','important')
                                  el.style.setProperty('margin-bottom','20px','important')
                                }
                              }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
                      <p ref={(el) => {
                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                          if (isTab) {
                            el.style.setProperty('font-size', '20px', 'important');
                            el.style.setProperty('line-height', '32px', 'important');
                            el.style.setProperty('max-width', '100%', 'important');
                          } else {
                            el.style.setProperty('line-height', '24px', 'important');
                            // el.style.setProperty('padding', '24px', 'important');
                          }
                        }
                      }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && (isTab ? '16px' : '14px') }} >We released our first sustainability report in 2004 and have maintained annual disclosures since then. Our full set of reports are available here.</p>
                      <a href="/business-and-capabilities" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >LEARN&nbsp;MORE</div>
                      </a>
                    </div>
                  </div>
                  <div ref={(el) => {
                    if (el && isTab) {
                      el.style.setProperty('height', '280px', 'important')
                    }
                  }} className='af-class-scroll-video-block' style={{
                    height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '40vh' : 'auto',
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "45%",
                    marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '20px' : "8%",
                    // padding: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px',
                    paddingBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '20px',

                  }}>
                    <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/sustainability_poster_mobile.png" : "/images/sustainability_poster_mobile.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover', marginBottom: '20px' } : { height: '100%', width: '100%', objectFit: 'cover' }} />
                  </div>
                </div>
              </div>
            </div>
            }


            {/* <VideoSection fullScreenVideo={() => {
              this.setState({ hideMenu: !this.state.hideMenu })
            }} /> */}


            {/* {
              window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                ?
                this.state.articleData?.section1 && this.state.articleData?.section1.length && <ImageSliderMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
                :
                <ImageSlider data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
            } */}


            {/* <span className="af-view" style={{ position: 'relative', width: '240vw', }} > */}
            {/* <div className='af-class-section'>
              {
                this.state.articleData.section1 && this.state.articleData.section1.length !== 0 && <ImageSliderGsap data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
              }
            </div> */}
            {/* </span> */}

            <div id="community-initiatives" data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085ce" className="af-class-section af-class-our-values" style={{ height: '260vh' }}>
              <div className="af-class-values-section-title-block">
                {/* <div className="af-class-container" style={{ alignItems: 'center' }} > */}
                {/* style={{ lineHeight: 1 }} */}
                <h1 className="af-class-h2-section-heading af-class-title af-class-values" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >Our community programmes</h1>
                {/* style={{ paddingLeft: '25%', paddingRight: '25%', alignSelf: 'center', textAlign: 'center' }}  */}
                <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if (isTab) {
                      el.style.setProperty('font-size', '24px', 'important');
                      el.style.setProperty('line-height', '40px', 'important');
                      el.style.setProperty('max-width', '70%', 'important');
                    } else {
                      el.style.setProperty('line-height', '30px', 'important');
                    }
                  }
                }} className="af-class-paragraph af-class-_572" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px', maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '320px' }} >Giving back to society is a tenet our founder Dr. Anji Reddy instilled in the ethos of our company. To this end, our community development programmes target four broad areas: Education, Skilling and Livelihood, Health and Environmental Sustainability</p>
                {/* </div> */}
              </div>

              <div className="af-class-values-background-wrapper af-class-z-index-2" style={{ backgroundColor: '#EDEDED', paddingBottom: '120px', paddingTop: '120px', height: 'auto' }}>
                {/* <div className="af-class-section af-class-z-index-2" style={{ backgroundColor: '#EDEDED' }} > */}
                {/* data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf"  */}
                <div className="af-class-container w-container">
                  <h2 className="af-class-h2-section-heading">Education</h2>
                  <EducationSlider />
                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                    <div className="af-class-drag-slider-wrapper">
                      <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        {/* <div className="af-class-mask af-class-_350 w-slider-mask"> */}
                        <div className="af-class-mask w-slider-mask">

                          <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                            <div className="af-class-slide-div">
                              <div>
                                <div className="af-class-drag-slider-item-thumbnail"><img src="/images/sharath-kumar-Mb2LErousEY-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                <h3 className="af-class-h3-heading af-class-normal-typcase">Kallam Anji Reddy Vidyalaya</h3>
                                <div>Established in 2001, Kallam Anji Reddy Vidyalaya strives to enhance the quality of education for children from underprivileged communities. The school follows the SSC syllabus and offers instruction in English from Nursery to Class 10. Currently the initiative supports around 2300 children.</div>
                              </div>
                            </div>
                          </div>

                          <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                            <div className="af-class-slide-div">
                              <div>
                                <div className="af-class-drag-slider-item-thumbnail"><img src="/images/yogendra-singh-RgDVFbXllVA-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                <h3 className="af-class-h3-heading af-class-normal-typcase">Kallam Anji Reddy Junior Vocational College</h3>
                                <div>The vocational college was started in 2003 to enable matriculate students from low income families attain employment orientated technical education. Along with eight employment oriented courses, KARVJC offers bridge courses to enable interested students to develop a strong foundation for higher education.</div>
                              </div>
                            </div>
                          </div>

                          <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                            <div className="af-class-slide-div">
                              <div>
                                <div className="af-class-drag-slider-item-thumbnail"><img src="/images/akshar-dave-b5UILRPGV_g-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                <h3 className="af-class-h3-heading af-class-normal-typcase">School Improvement Programme (SIP)</h3>
                                <div>SIP was conceived to enhance the quality of education imparted in government schools. SIP focuses on the holistic development of the students and undertakes a number of initiatives in the area of Foundational Literacy, Numeracy & English language, Maths, Science, Sports, and WASH to enhance overall learning outcomes. SIP is implemented in 100 government schools in Telangana and Andhra Pradesh. The project is implemented in partnership with the Dr. Reddy’s Foundation.</div>
                              </div>
                            </div>
                          </div>

                          <div data-w-id={"ae2c330c-3b91-6fe2-293a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                            <div className="af-class-slide-div">
                              <div>
                                <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Sashakt-Web.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                <h3 className="af-class-h3-heading af-class-normal-typcase">Sashakt</h3>
                                <div>Sashakt Scholarship supports female students from low socio-economic backgrounds to pursue a career in science. They are offered financial support and mentorship throughout their undergraduate studies. Junior Sashakt Scholarship supports academically bright girls who have completed 10th class. This initiative is implemented in partnership with Dr. Reddy’s Foundation.</div>
                              </div>
                            </div>
                          </div>

                        </div>

                        {/* <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50a" className="af-class-left-arrow af-class-lower af-class-down-90px w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50e" className="af-class-right-arrow af-class-lower af-class-down-90px w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e510" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div> */}

                        <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: this.state.leftDisabled ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={(e) => this.setState({ rightDisabled: false, leftDisabled: true })}>
                          {
                            this.state.leftDisabled ?
                              <>
                                <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                              </>
                              :
                              <>
                                <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                              </>
                          }

                        </div>
                        <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: this.state.rightDisabled ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={(e) => this.setState({ rightDisabled: true, leftDisabled: false })}>
                          {
                            this.state.rightDisabled ?
                              <>
                                <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                              </>
                              :
                              <>
                                <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                              </>
                          }
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" />
                      </div>
                    </div>
                    <div className="af-class-drag-slider-progress-bar">
                      <div className="af-class-drag-progress-fill af-class-fifty" />
                    </div>
                  </>
                  }
                </div>
                {/* </div> */}
              </div>
            </div>


            <div data-w-id="19cc00fd-e1b3-336b-5837-c6cf2129d595" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ lineHeight: 1.2, fontSize: isTab ? '60px' : '36px' }} >We enhance youth employability and enable farmers to prosper through skill and capacity-building programmes</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ lineHeight: 1.2, fontSize: '60px' }} >We enhance youth<br />employability and enable<br />farmers to prosper through<br />skill and capacity-building programmes<br /></h1>
                  }
                </div>
              </div>
            </div>

            <div className="af-class-section af-class-z-index-2" >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">Livelihood</h2>
                <LivelihoodSlider />
                {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                  <div className="af-class-drag-slider-wrapper">
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                      {/* <div className="af-class-mask af-class-_350 w-slider-mask"> */}
                      <div className="af-class-mask w-slider-mask">

                        <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/shutterstock_1181684455.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Youth Skilling</h3>
                              {/* <div>Developed in 2016, GROW provides industry-aligned core employability skills that are domain agnostic and meet requirements across multiple sectors for 18 to 30-year-old graduates. GROW was earlier knows as LABS. The project is implemented in partnership with the Dr. Reddy’s Foundation.</div> */}
                              <div>Youth Skilling initiatives focus on better skills and better jobs. It aims at delivering industry-aligned core employability skills, which are domain agnostic and meet the requirements of multiple sectors. GROW helps place youth in quality positions matching their aspirations. The project is implemented in partnership with the Dr. Reddy’s Foundation.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Mask_Group_299.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Skilling for Persons with Disabilities</h3>
                              <div>Placement-linked skilling programme for youth with physical challenges and vision, speech and hearing impairment. The programme is implemented in partnership with the Dr. Reddy’s Foundation and Cheshire Disability Trust.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Mask-Group-297.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Healthcare Skilling</h3>
                              <div>The availability of qualified human resource has emerged as a significant challenge facing healthcare delivery mechanism. To combat the problem, the healthcare skilling program trains youth who seek employment opportunity in the non-medical healthcare sector. The programme is implemented in partnership with Dr.Reddy’s Foundation.</div>
                              {/* <div>Our HQHCS programme trains youth who seek employment in the non-medical healthcare domain. It has been developed by industry experts and is taught by highly qualified trainers. HQHCS is implemented in partnership with the Dr. Reddy’s Foundation.</div> */}
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-293a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/karthik-s-fo5ZvAVvhYk-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Making Integrated Transformation through Resourceful Agriculture (MITRA)</h3>
                              <div>MITRA aims at empowering farmers by encouraging them to adopt the latest technology and farming practices. The goal is to enhance productivity and reduce input costs, resulting in an increase in farmers’ incomes. MITRA is implemented in partnership with the Dr. Reddy’s Foundation.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-293a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/richard-saunders-_e-KrDQ_M8Q-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Farmer Field Schools</h3>
                              <div>In partnership with the Naandi Foundation, we run Farmer Field Schools to empower farmers working at coffee plantations in the Araku Valley region of Andhra Pradesh, India. They are trained in organic biodynamic farming, farmer cooperation and business planning.</div>
                            </div>
                          </div>
                        </div>

                      </div>

                      {/* <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50a" className="af-class-left-arrow af-class-lower af-class-down-90px w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50e" className="af-class-right-arrow af-class-lower af-class-down-90px w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e510" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div> */}


                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: this.state.leftDisabled2 ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={(e) => this.setState({ rightDisabled2: false, leftDisabled2: true })}>
                        {
                          this.state.leftDisabled2 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }

                      </div>
                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: this.state.rightDisabled2 ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={(e) => this.setState({ rightDisabled2: true, leftDisabled2: false })}>
                        {
                          this.state.rightDisabled2 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }
                      </div>



                      <div className="af-class-slide-nav w-slider-nav w-round" />
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar">
                    <div className="af-class-drag-progress-fill af-class-fifty" />
                  </div> </>}
              </div>
            </div>

            {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ea" className="af-class-section af-class-always-innovating" >
              <div className="af-class-always-innovating-heading-block">
                <div className="af-class-container">
                  {/* <h1 data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ed" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '46px' : '36px' : '60px' }} className="af-class-h2-section-heading af-class-title af-class-center-align af-class-_1000px">We enable healthcare professionals to understand their patients better</h1> */}
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      isTab
                        ?
                        <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: isTab ? '80%' : '100%', fontSize: isTab ? '48px' : '36px' }} >Good Health Can’t Wait, so our efforts are focused on helping communities that lack access to reliable healthcare</h1>
                        :
                        <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: isTab ? '80%' : '100%', fontSize: isTab ? '48px' : '36px' }} >Good Health Can’t <br /> Wait, so our <br /> efforts are focused <br /> on helping communities that lack access to reliable healthcare</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: '80%', fontSize: '60px' }} >Good Health Can’t Wait,<br />so our efforts are focused<br />on helping communities<br />that lack access to <br /> reliable healthcare</h1>
                  }
                </div>
              </div>
              <div className="af-class-science-research-fullbg-wrapper">
                <div className="af-class-full-bg-block">
                  <div className="af-class-section af-class-form-formulations af-class-typeb" style={{ backgroundImage: `linear-gradient( 180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75) ),url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', }} >
                    <div className="af-class-container w-container">
                      <div className={!isTab && "w-layout-grid af-class-_2-col-grid"}>
                        <div>
                          {/* <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“We have hosted numerous sessions of DRFHE’s Sarathi and Abhilasha programmes and our employees are satisfied with the learnings, especially with regard to aspects like time management and anger management. We also conducted a Nursing Leadership Programme and have seen a great difference in the way our nurses have been taking care of their teams. And last year, DRFHE rolled out new initiatives tailored to the pandemic situation and people really liked it.”<br /><br /><strong>Vaishali Panchal<br />Training and Development | Rainbow Hospitals, Hyderabad</strong></p> */}
                          <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              if (isTab) {
                                el.style.setProperty('line-height', '60px', 'important');
                                el.style.setProperty('margin-bottom', '10px', 'important');
                              } else {
                                el.style.setProperty('line-height', '48px', 'important');
                              }
                            }
                          }} style={{ color: '#fff', fontSize: isTab ? '50px' : '32px', lineHeight: isTab && '60px', textAlign: 'center', fontWeight: 300, filter: 'opacity(1)' }} >Roshni Helpline</p>
                          <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              if (isTab) {
                                el.style.setProperty('line-height', '40px', 'important');
                              } else {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }
                          }} style={{ color: '#fff', fontSize: isTab ? '20px' : '16px', fontWeight: 200, paddingLeft: '10%', paddingRight: '10%', textAlign: 'center', filter: 'opacity(1)', lineHeight: isTab ? '40px' : '28px' }} >In partnership with the Roshni Trust, our Roshni Helpline extends tele-counselling support for emotional and mental well-being. Need-based in-person counselling is also provided at Roshni’s care centre.</p>
                        </div>
                        {/* <div>
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="6e51dac0-158a-08be-971b-e997baea8bc9" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“Mahatma Gandhi once said that unless we educate our people, we will not get full freedom. The Awareness of Life initiative by Dr. Reddy’s to educate people is a novel programme, raising awareness among them on issues like diabetes, hypertension, obesity, cancer and osteoporosis. These are well-chosen topics and as a local practitioner who is part of the programme, I am happy to do this work because it will help humanity in the long run.” <br /><br /><strong>Dr. R Govindharajan<br />MD | Madurai</strong></p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="af-class-section af-class-focused-market af-class-type-a" style={{ backgroundImage: `linear-gradient( 180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75) ),url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', }} >
                    <div className="af-class-container w-container">
                      <div className={!isTab && "w-layout-grid af-class-_2-col-grid"}>
                        <div>
                          {/* <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="6e51dac0-158a-08be-971b-e997baea8bc9" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“Trainings are integral to employees’ skill development. They help in the areas of communication, leadership, time management, motivation, empathy for patients, team building, etc — all essential for healthcare workers. We are fortunate to work with Dr. Reddy’s through trainings like Abhilasha, Sarathi, Mantra and Manthan. Additionally, thanks to their outbound training programme for the senior leadership team, they helped us hone our leadership skills.”  <br /><br /><strong>Nithya Kalyani<br />HR Head | MS Ramaiah Memorial Hospital, Bangalore</strong></p> */}
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              if (isTab) {
                                el.style.setProperty('line-height', '60px', 'important');
                                el.style.setProperty('margin-bottom', '10px', 'important');
                              } else {
                                el.style.setProperty('line-height', '48px', 'important');
                              }
                            }
                          }} data-w-id="6e51dac0-158a-08be-971b-e997baea8bc9" style={{ color: '#fff', fontSize: isTab ? '50px' : '32px', lineHeight: isTab && '60px', textAlign: 'center', fontWeight: 300, filter: 'opacity(1)' }} >Community Health Intervention <br /> Programme (CHIP)</p>
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              if (isTab) {
                                el.style.setProperty('line-height', '40px', 'important');
                              } else {
                                el.style.setProperty('line-height', '28px', 'important');
                              }
                            }
                          }} data-w-id="6e51dac0-158a-08be-971b-e997baea8bc9" style={{ color: '#fff', fontSize: isTab ? '20px' : '16px', fontWeight: 200, paddingLeft: '10%', paddingRight: '10%', textAlign: 'center', filter: 'opacity(1)', lineHeight: isTab ? '40px' : '28px' }} >Community Health Intervention Programme or CHIP focuses on providing primary healthcare services in rural communities at the doorste. of people who do not have access to healthcare. Implemented in partnership with NICE Foundation, CHIP is operational in 155 villages of Andhra Pradesh, extending services to a population of around 2 lakhs.</p>
                        </div>
                        {/* <div id="w-node-dbce085d-578a-b8c3-d4be-9efe94e545fe-b3513499">
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ff" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“DRFHE is doing fantastic work with us, such as the Sarathi module for customer care executives, Sanjeevani for pharmacists, Inner Circle for doctors, Abhilasha for nurses, and Manthan and Mantra for heads of departments and managerial staff. These programmes consist of real examples, role plays, etc, which are very effective. They’re building soft skills in our staff and making them more confident. Our patient feedback is positive, and complaints have reduced.”<br /><br /><strong>Megha Gaikwad<br />Deputy HR Manager | Ruby Hall Clinic, Pune</strong></p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}

            {/* data-w-id="8b77eb71-4078-dfa5-9dc2-6a816a3fa874" */}
            {/* <div data-w-id={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "dbce085d-578a-b8c3-d4be-9efe94e545ea" : "8b77eb71-4078-dfa5-9dc2-6a816a3fa874"} className="af-class-section af-class-embracing-quality">
              <div className="af-class-vision-heading-block af-class-_2">
                <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container" style={{ alignItems: 'center', justifyItems: 'center' }} >
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: isTab ? '80%' : '100%', fontSize: isTab ? '48px' : '36px' }} >Good Health Can’t <br /> Wait, so our <br /> efforts are focused <br /> on helping communities that lack access to reliable healthcare</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: '80%', fontSize: '60px' }} >Good Health Can’t Wait,<br />so our efforts are focused<br />on helping communities<br />that lack access to <br /> reliable healthcare</h1>
                  }
                </div>
              </div>
              {
                window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && this.state.articleData?.section1 && this.state.articleData?.section1.length && <HealthMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
              }
            </div> */}

            {
              !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                <div data-w-id={"8b77eb71-4078-dfa5-9dc2-6a816a3fa874"} className="af-class-section af-class-embracing-quality">
                  <div className="af-class-vision-heading-block af-class-_2">
                    <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container" style={{ alignItems: 'center', justifyItems: 'center' }} >
                      {
                        window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                          ?
                          <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: isTab ? '80%' : '100%', fontSize: isTab ? '48px' : '36px' }} >Good Health Can’t <br /> Wait, so our <br /> efforts are focused <br /> on helping communities that lack access to reliable healthcare</h1>
                          :
                          <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ lineHeight: '1.2', maxWidth: '80%', fontSize: '60px' }} >Good Health Can’t Wait,<br />so our efforts are focused<br />on helping communities<br />that lack access to <br /> reliable healthcare</h1>
                      }
                    </div>
                  </div>
                </div>
                <div className='af-class-section'
                // style={{ display: 'flex', paddingTop: "40px", paddingBottom: '40px', flexDirection: 'column', marginLeft: '12vw', marginRight: '12vw',zIndex:99999 }}
                >
                  <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container">
                    <h2 className="af-class-h2-section-heading">Health</h2>
                    <Health />
                  </div>
                </div>
              </>
            }

            {/* {
              window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                ?
                this.state.articleData?.section1 && this.state.articleData?.section1.length &&  <HealthMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
                :
                <div className='af-class-section'
                // style={{ display: 'flex', paddingTop: "40px", paddingBottom: '40px', flexDirection: 'column', marginLeft: '12vw', marginRight: '12vw',zIndex:99999 }}
                >
                  <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container">
                    <h2 className="af-class-h2-section-heading">Health</h2>
                    <Health />
                  </div>
                </div>
            } */}

            <section id="business-sustainability-education" data-w-id="f887d200-5ac6-999a-d1b1-a054378cd6a8" className="af-class-section af-class-peple-stories-hero">
              <div className="af-class-container w-container">
                {
                  window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                    ?
                    this.state.articleData?.section1 && this.state.articleData?.section1.length && <EnvironmentMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
                    :
                    <ImageTextSlider />
                }
              </div>
            </section>

            <div id="volunteering" className="af-class-section af-class-helping-hcps" >
              <div className="af-class-container w-container">
                <div className="af-class-helping-hcps" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column' : 'row', width: '100%' }} >
                  <h2 id="w-node-d47114d1-361a-ddbf-a1b5-f8127a8500c5-0dfa92f5" data-w-id="d47114d1-361a-ddbf-a1b5-f8127a8500c5" style={{ opacity: 0, maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100vw' : '50vw', marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} className="af-class-h2-section-heading af-class-title af-class-negative-margin af-class-show-mobile">Dr. Anji Reddy’s <br />Spirit of Giving</h2>
                  <div data-w-id="c1cc8191-6a9f-e391-b59d-2370c92d6b67" style={{ opacity: 0, width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100%', marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100px' }}>
                    <p ref={(el) => {
                      if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                        if (isTab) {
                          el.style.setProperty('font-size', '20px', 'important');
                          el.style.setProperty('line-height', '36px', 'important');
                        } else {
                          el.style.setProperty('line-height', '28px', 'important');
                        }
                      }
                    }} className="af-class-paragraph af-class-_572" style={{ maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100vw' : '100%', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                      Actions speak louder than words and nothing exemplifies this better than what we do to build better communities. At the commemoration of our founder’s passing every year, we launch Dr. Anji Reddy’s Spirit of Giving programme, where our employees across the world volunteer and contribute their time and effort to make a difference to society.
                      <br /><br />
                      Fostering scientific temper among schoolchildren, spending time with senior citizens, bringing cheer to orphans, spreading awareness about traffic rules, working to restore a cyclone-ravaged zoo — the causes are many and diverse, but the commitment is the same.
                      <br /><br />
                      The Dr. Anji Reddy’s Spirit of Giving programme truly demonstrates the culture of giving Dr. Anji Reddy imbued in our company ethos.
                    </p>
                    {/* <a data-w-id="16bb5bea-e192-63b9-5d03-75b6585ba516"
                        href={`/articles?${this.state.articleData.section2[0].contentId}#${this.state.articleData.section2[0].id}`}
                        // href={"/articles"}
                        //   onClick={() => {
                        //     // this.props.history.push("/articles", { data: item })
                        //     localStorage.setItem("data", JSON.stringify(this.state.articleData.section2[0]))
                        //   }}
                        className="af-class-link-arrow-white af-class-center-align w-inline-block" >
                        <div className="af-class-link-circle af-class-white" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow af-class-hide-mobile" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                      </a> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div id="our-affiliates" className="af-class-section">
              <div className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">Our affiliates</h2>
                <div className="w-dyn-list">
                  <div role="list" className="af-class-articles-wrapper w-dyn-items">
                    {
                      this.state.articleData.section3 && this.state.articleData.section3.length
                        ?
                        this.state.articleData.section3.map((item, index) => {
                          return <div key={"Section_3_" + index} role="listitem" className="w-dyn-item">
                            <div data-w-id="a3de4921-36ea-576e-520a-3d4b12993d0b" style={{ opacity: 0 }} className="af-class-articles-block">
                              <a href={item.externalLink} target="_blank"
                                onClick={(ev) => {
                                  if (window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                    ev.preventDefault()
                                  }
                                }}
                                className="af-class-article-text-thumbnail-wrapper w-inline-block">
                                <div className="af-class-articles-thumbnail"><img src={item.thumbnail} loading="lazy" alt className="af-class-articles-image" /></div>
                                <div className="af-class-article-blurb-text">
                                  <h3 className="af-class-article-title-text" style={{ fontSize: '18px' }} >{item.title}</h3>
                                  {
                                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && this.state.readMore !== item.id
                                      ?
                                      <span>
                                        <RenderText className="af-class-paragraph af-class-_600" style={{fontSize: '14px',}} dangerouslySetInnerHTML={{ __html: item.description }} />
                                        <span style={{ color: '#5225B5', fontSize: '14px', fontWeight: 'bold' }} onClick={() => {
                                          this.setState({ readMore: item.id })
                                        }} >Read More</span>
                                      </span>
                                      :
                                      <p className="af-class-paragraph af-class-_600" style={{fontSize: '14px',}} dangerouslySetInnerHTML={{ __html: item.description }} />
                                  }
                                </div>
                              </a>
                              {item.externalLink && item.externalLink.length && <div className="af-class-article-link-block-wrapper" style={{ justifyContent: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'flex-end' : 'flex-start', alignSelf: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'flex-end' : 'auto' }}>
                                <a href={item.externalLink} target="_blank" className="af-class-link-arrow af-class-below w-inline-block">
                                  <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                              </div>}
                            </div>
                          </div>
                        })
                        :
                        <div className="w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                    }
                  </div>
                </div>
              </div>


            </div> */}


            <div className="af-class-section af-class-knowledge-bank" >

              <div className="af-class-container w-container">
                <div>QUICK LINKS</div>
                <div className="af-class-why-spacer" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} />
                <div className="af-class-mediakit-links-inner-block">
                  {
                    this.state.quickLinksData.map((item, index) => {
                      return <a key={item.id} target="_blank" href={item.file} className="af-class-media-kit-links">{item.title}</a>
                    })
                  }
                </div>
              </div>

              <a href="#back-to-top" data-w-id="1e93b0bc-4d73-ee38-8cbc-f2757ef7c754" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="/images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="/images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>

            {/* <div data-w-id="d28f00b7-188d-cfc8-8445-c3fb65cbc3a7" style={{ opacity: 0 }} className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  <h1 className="af-class-h2-section-heading af-class-title af-class-width-800">Building communities <br />one day at a time</h1>
                </div>
              </div>
            <div data-w-id="d28f00b7-188d-cfc8-8445-c3fb65cbc3a6" className="af-class-section af-class-building-communities">
              <div className="af-class-bottom-stick-section">
                <div className="af-class-container w-container">
                  <div className="w-dyn-list">
                    <div role="list" className="af-class-articles-wrapper w-dyn-items">
                      <div role="listitem" className="w-dyn-item">
                        <div data-w-id="a3de4921-36ea-576e-520a-3d4b12993d0b" style={{ opacity: 0 }} className="af-class-articles-block">
                          <a href="#" className="af-class-article-text-thumbnail-wrapper w-inline-block">
                            <div className="af-class-articles-thumbnail"><img src loading="lazy" alt className="af-class-articles-image" /></div>
                            <div className="af-class-article-blurb-text">
                              <h3 className="af-class-article-title-text" />
                              <p className="af-class-paragraph af-class-_600" />
                            </div>
                          </a>
                          <div className="af-class-article-link-block-wrapper">
                            <a href="#" className="af-class-link-arrow af-class-below w-inline-block">
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-dyn-empty">
                      <div>No items found.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(BusinessResponsibilityAndSustainabilityView)

/* eslint-enable */
